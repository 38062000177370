const currencies = require("./currencies.json");

export const getCurrency = () => {
  // eslint-disable-next-line no-constant-condition
  if (Intl && false) {
    // eslint-disable-next-line new-cap
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split("/");
    const userCity = tzArr[tzArr.length - 1];

    let userCountry = "";

    for (const country in currencies) {
      if (
        currencies[country]?.cities?.includes(userCity) &&
        currencies[country].currency !== ""
      ) {
        userCountry = country;
        break;
      }
    }

    if (userCountry === "") {
      // Default to USD
      userCountry = "United States";
    }
    const currency = currencies[userCountry];
    return currency;
  }

  // Default to USD
  return {
    currency: "USD",
    currencySymbol: "$",
  };
};
