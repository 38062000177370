/* eslint-disable valid-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import find from "lodash/find";
import split from "lodash/split";
import forEach from "lodash/forEach";
import axios from "axios";
import business from "moment-business";
import moment from "moment";
import { defaultDiamondData, locationArr } from "../services/constants";
import { addProductToCustomCollection } from "../services/shopify";
import { getCookies } from "cookies-next";
import lowerCase from "lodash/lowerCase";

export function getRandomIntByRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getWindowDimensions() {
  let height = 0;
  let width = 0;

  if (window.innerHeight || document.documentElement || document.body) {
    height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
  }

  if (window.innerWidth || document.documentElement || document.body) {
    width = window.innerWidth || document.documentElement || document.body;
  }

  return {
    width,
    height,
  };
}

// eslint-disable-next-line valid-jsdoc
/**
 * @description Function to get the cloud front url
 * @param {*} image
 */
export const getCloudFrontUrl = (
  image = "",
  replaceUrl = "https://shop.frankdarling.com",
  avif = false
) => {
  if (!image) return "";
  if (!image?.includes("https://")) return image;
  const replaceImages = new RegExp(replaceUrl, "g");
  let t = 0;
  const imagePath = image
    ?.replace(replaceImages, "")
    .replace(/files/g, (match) => (++t === 2 ? "products" : match)); // this will replace the second occurnce of files with products in the path
  if (imagePath.includes(".gif")) {
    return {
      src: `https://d1s0gvn7kxaior.cloudfront.net${imagePath}`,
    };
  }

  if (avif) {
    const imagePathWithoutExtension = imagePath
      .replace(
        /\.(jpg|jpeg|png|webp|mp4\.jpg|mp4\.jpeg|mp4\.png|mp4\.webp)(\?.*)?$/,
        ""
      )
      .replace(/\.$/, ""); // sometime double dot present before the extension

    return {
      src: `https://uploads.frankdarling.com${imagePathWithoutExtension}.avif`,
    };
  }

  // muting the replaceUrl until control panel get fixed
  // return {
  //   sizes: "(max-width: 600px) 600vw",
  //   src: `https://images.frankdarling.com/fit-in/900x900${imagePath}`,
  //   srcSet: `https://images.frankdarling.com/fit-in/600x600${imagePath} 600w, https://images.frankdarling.com/fit-in/900x900${imagePath} 1500w`,
  // };

  return {
    src: image,
  };
};

export const diamondTypeToSearchString = (type) => {
  if (type === "Natural") return "Natural Diamond";
  else if (type === "Lab") return "Lab Diamond";
  else return type;
};

export const selectDiamondSeoImage = (type) => {
  const diamondCartImages = {
    asscher: "/assets/images/diamond-item/asscher.png",
    cushion: "/assets/images/diamond-item/cushion.png",
    elongatedCushion: "/assets/images/diamond-item/elongatedCushion.png",
    emerald: "/assets/images/diamond-item/emerald.png",
    marquise: "/assets/images/diamond-item/marquise.png",
    "old european": "/assets/images/diamond-item/oldEuropean.png",
    oldEuropean: "/assets/images/diamond-item/oldEuropean.png",
    "old mine": "/assets/images/diamond-item/oldMine.png",
    oldMine: "/assets/images/diamond-item/oldMine.png",
    oval: "/assets/images/diamond-item/oval.png",
    pear: "/assets/images/diamond-item/pear.png",
    princess: "/assets/images/diamond-item/princess.png",
    radiant: "/assets/images/diamond-item/radiant.png",
    "rose cut": "/assets/images/diamond-item/roseCut.png",
    roseCut: "/assets/images/diamond-item/roseCut.png",
    round: "/assets/images/diamond-item/round.png",
    trillion: "/assets/images/diamond-item/trillion.png",
  };
  return diamondCartImages[type];
};

export const selectDiamondOverlayImage = (type) => {
  const diamondOverlayImages = {
    asscher: "/assets/images/diamond-item/asscher.png",
    cushion: "/assets/images/diamond-item/cushion.png",
    "elongated cushion": "/assets/images/diamond-item/elongatedCushion.png",
    emerald: "/assets/images/diamond-item/emerald.png",
    marquise: "/assets/images/diamond-item/marquise.png",
    "old european": "/assets/images/diamond-item/oldEuropean.png",
    "old mine": "/assets/images/diamond-item/oldMine.png",
    oval: "/assets/images/diamond-item/oval.png",
    pear: "/assets/images/diamond-item/pear.png",
    princess: "/assets/images/diamond-item/princess.png",
    radiant: "/assets/images/diamond-item/radiant.png",
    "rose cut": "/assets/images/diamond-item/roseCut.png",
    round: "/assets/images/diamond-item/round.png",
    trillion: "/assets/images/diamond-item/trillion.png",
  };
  return diamondOverlayImages[type];
};

export const getLinkTextWithoutSlashPrefix = (link) => {
  if (link && link[0] === "/") return link.substring(1);
  return link;
};

export const caratToPixel = (value, shape, multiplier) => {
  function getObjectKey(diamondShape) {
    switch (diamondShape) {
      case "asscher":
      case "cushion":
      case "old european":
      case "old mine":
      case "princess":
      case "elongated cushion":
        return "asscher";

      case "emerald":
      case "radiant":
        return "emerald";

      case "marquise":
        return "marquise";

      case "oval":
        return "oval";

      case "round":
        return "round";

      case "pear":
        return "pear";

      case "rose cut":
      case "trillion":
        return "rose cut";

      default:
        return "asscher";
    }
  }
  const shapes = {
    asscher: {
      0.2: 4.8,
      0.3: 4.9,
      0.4: 5.0,
      0.5: 5.1,
      0.6: 5.2,
      0.7: 5.3,
      0.8: 5.4,
      0.9: 5.5,
      1.0: 5.6,
      1.1: 5.7,
      1.2: 5.9,
      1.3: 6.1,
      1.4: 6.3,
      1.5: 6.5,
      1.6: 6.58,
      1.7: 6.66,
      1.8: 6.76,
      1.9: 6.88,
      2.0: 7.0,
      2.1: 7.1,
      2.2: 7.2,
      2.3: 7.3,
      2.4: 7.4,
      2.5: 7.5,
      2.6: 7.6,
      2.7: 7.7,
      2.8: 7.8,
      2.9: 7.9,
      3.0: 8.0,
      3.1: 8.1,
      3.2: 8.2,
      3.3: 8.3,
      3.4: 8.4,
      3.5: 8.5,
      3.6: 8.6,
      3.7: 8.7,
      3.8: 8.8,
      3.9: 8.9,
      4.0: 9.0,
      4.1: 9.05,
      4.2: 9.1,
      4.3: 9.15,
      4.4: 9.2,
      4.5: 9.25,
      4.6: 9.3,
      4.7: 9.35,
      4.8: 9.4,
      4.9: 9.45,
      5.0: 9.5,
    },

    emerald: {
      0.2: 3.96,
      0.3: 4.64,
      0.4: 5.32,
      0.5: 6.0,
      0.6: 6.2,
      0.7: 6.4,
      0.8: 6.6,
      0.9: 6.8,
      1.0: 7.0,
      1.1: 7.12,
      1.2: 7.24,
      1.3: 7.3,
      1.4: 7.42,
      1.5: 7.5,
      1.6: 7.7,
      1.7: 7.9,
      1.8: 8.0,
      1.9: 8.3,
      2.0: 8.5,
      2.1: 8.6,
      2.2: 8.7,
      2.3: 8.8,
      2.4: 8.9,
      2.5: 9.0,
      2.6: 9.06,
      2.7: 9.12,
      2.8: 9.18,
      2.9: 9.24,
      3.0: 9.3,
      3.1: 9.39,
      3.2: 9.48,
      3.3: 9.57,
      3.4: 9.66,
      3.5: 9.75,
      3.6: 9.8,
      3.7: 9.85,
      3.8: 9.9,
      3.9: 9.95,
      4.0: 10,
      4.1: 10.1,
      4.2: 10.2,
      4.3: 10.3,
      4.4: 10.4,
      4.5: 10.5,
      4.6: 10.6,
      4.7: 10.7,
      4.8: 10.8,
      4.9: 10.9,
      5.0: 11,
    },

    round: {
      0.2: 5.4,
      0.3: 5.5,
      0.4: 5.6,
      0.5: 5.8,
      0.6: 6.0,
      0.7: 6.2,
      0.8: 6.3,
      0.9: 6.4,
      1.0: 6.5,
      1.1: 6.62,
      1.2: 6.74,
      1.3: 7.0,
      1.4: 7.15,
      1.5: 7.3,
      1.6: 7.42,
      1.7: 7.54,
      1.8: 7.7,
      1.9: 7.89,
      2.0: 8.0,
      2.1: 8.23,
      2.2: 8.35,
      2.3: 8.4,
      2.4: 8.48,
      2.5: 8.7,
      2.6: 8.83,
      2.7: 8.94,
      2.8: 9.06,
      2.9: 9.18,
      3.0: 9.1,
      3.1: 9.21,
      3.2: 9.33,
      3.3: 9.44,
      3.4: 9.58,
      3.5: 9.75,
      3.6: 9.82,
      3.7: 9.89,
      3.8: 9.95,
      3.9: 10.02,
      4.0: 10.25,
      4.1: 10.31,
      4.2: 10.35,
      4.3: 10.43,
      4.4: 10.49,
      4.5: 10.75,
      4.6: 10.8,
      4.7: 10.85,
      4.8: 10.9,
      4.9: 10.95,
      5.0: 11.0,
    },

    "rose cut": {
      0.2: 4.1,
      0.3: 4.2,
      0.4: 4.8,
      0.5: 5.2,
      0.6: 5.4,
      0.7: 5.8,
      0.8: 6.0,
      0.9: 6.4,
      1.0: 7.0,
      1.1: 7.2,
      1.2: 7.3,
      1.3: 7.4,
      1.4: 7.6,
      1.5: 7.8,
      1.6: 7.9,
      1.7: 8.0,
      1.8: 8.0,
      1.9: 8.0,
      2.0: 8.2,
      2.1: 8.4,
      2.2: 8.6,
      2.3: 8.7,
      2.4: 8.8,
      2.5: 9.0,
      2.6: 9.2,
      2.7: 9.4,
      2.8: 9.5,
      2.9: 9.6,
      3.0: 9.8,
      3.1: 9.9,
      3.2: 10.1,
      3.3: 10.3,
      3.4: 10.4,
      3.5: 10.5,
      3.6: 10.6,
      3.7: 10.7,
      3.8: 10.8,
      3.9: 10.9,
      4.0: 11.0,
      4.1: 11.1,
      4.2: 11.2,
      4.3: 11.3,
      4.4: 11.4,
      4.5: 11.5,
      4.6: 11.6,
      4.7: 11.7,
      4.8: 11.8,
      4.9: 11.9,
      5.0: 12.0,
    },

    marquise: {
      0.2: 5.6,
      0.3: 6.4,
      0.4: 7.2,
      0.5: 8.0,
      0.6: 8.4,
      0.7: 8.8,
      0.8: 9.2,
      0.9: 9.6,
      1.0: 10,
      1.1: 10.4,
      1.2: 10.8,
      1.3: 11.2,
      1.4: 11.6,
      1.5: 12.0,
      1.6: 12.2,
      1.7: 12.4,
      1.8: 12.6,
      1.9: 12.8,
      2.0: 13,
      2.1: 13.2,
      2.2: 13.4,
      2.3: 13.6,
      2.4: 13.8,
      2.5: 14.0,
      2.6: 14.2,
      2.7: 14.4,
      2.8: 14.6,
      2.9: 14.8,
      3.0: 15,
      3.1: 15.15,
      3.2: 15.3,
      3.3: 15.45,
      3.4: 15.6,
      3.5: 15.75,
      3.6: 15.9,
      3.7: 16.05,
      3.8: 16.2,
      3.9: 16.35,
      4.0: 16.5,
      4.1: 16.55,
      4.2: 16.6,
      4.3: 16.65,
      4.4: 16.7,
      4.5: 16.75,
      4.6: 16.8,
      4.7: 16.85,
      4.8: 16.9,
      4.9: 16.95,
      5.0: 17,
    },
    oval: {
      0.2: 4.8,
      0.3: 5.2,
      0.4: 5.6,
      0.5: 6.0,
      0.6: 6.4,
      0.7: 6.8,
      0.8: 7.2,
      0.9: 7.6,
      1.0: 7.7,
      1.1: 7.82,
      1.2: 7.94,
      1.3: 8.06,
      1.4: 8.18,
      1.5: 8.5,
      1.6: 8.7,
      1.7: 8.9,
      1.8: 9.1,
      1.9: 9.3,
      2.0: 9,
      2.1: 9.2,
      2.2: 9.4,
      2.3: 9.6,
      2.4: 9.8,
      2.5: 10.0,
      2.6: 10.4,
      2.7: 10.8,
      2.8: 11.2,
      2.9: 11.6,
      3.0: 12,
      3.1: 12.05,
      3.2: 12.1,
      3.3: 12.15,
      3.4: 12.2,
      3.5: 12.0,
      3.6: 12.1,
      3.7: 12.2,
      3.8: 12.3,
      3.9: 12.4,
      4.0: 12.5,
      4.1: 12.55,
      4.2: 12.6,
      4.3: 12.65,
      4.4: 12.7,
      4.5: 12.75,
      4.6: 12.8,
      4.7: 12.85,
      4.8: 12.9,
      4.9: 12.95,
      5.0: 13,
    },

    pear: {
      0.2: 4.8,
      0.3: 5.2,
      0.4: 5.6,
      0.5: 6.0,
      0.6: 6.4,
      0.7: 6.8,
      0.8: 7.2,
      0.9: 7.6,
      1.0: 7.7,
      1.1: 7.82,
      1.2: 7.94,
      1.3: 8.06,
      1.4: 8.18,
      1.5: 8.0,
      1.6: 8.2,
      1.7: 8.4,
      1.8: 8.6,
      1.9: 8.8,
      2.0: 9,
      2.1: 9.2,
      2.2: 9.4,
      2.3: 9.6,
      2.4: 9.8,
      2.5: 10.0,
      2.6: 10.4,
      2.7: 10.8,
      2.8: 11.2,
      2.9: 11.6,
      3.0: 12,
      3.1: 12.2,
      3.2: 12.4,
      3.3: 12.6,
      3.4: 12.8,
      3.5: 13.0,
      3.6: 13.2,
      3.7: 13.4,
      3.8: 13.6,
      3.9: 13.8,
      4.0: 14.0,
      4.1: 14.1,
      4.2: 14.2,
      4.3: 14.3,
      4.4: 14.4,
      4.5: 14.5,
      4.6: 14.6,
      4.7: 14.7,
      4.8: 14.8,
      4.9: 14.9,
      5.0: 15,
    },
  };
  return shapes[getObjectKey(shape)][value] * (multiplier || 3.7);
};

export const caratToMeasurement = (shape, carat) => {
  const measurementChart = (shape) => {
    switch (shape) {
      case "Oval":
        return {
          1: "6.0 x 4.0",
          1.5: "7.0 x 5.0",
          2: "8.0 x 6.0",
          2.5: "8.5 x 6.5",
          3: "9.0 x 6.0",
          4: "10.0 x 7.0",
          5: "10.0 x 8.0",
          6: "12.0 x 8.0",
        };
      case "Emerald":
        return {
          1: "5.5 x 4.0",
          1.5: "6.0 x 4.0",
          2: "7.0 x 5.0",
          2.5: "7.25 x 5.25",
          3: "7.5 x 5.5",
          4: "9.0 x 6.0",
          5: "9.0 x 7.0",
          6: "10.0 x 7.0",
        };
      case "Cushion":
        return {
          1: "5.0 x 5.0",
          1.5: "5.75 x 5.75",
          2: "6.0 x 6.0",
          2.5: "6.75 x 6.75",
          3: "7.25 x 7.25",
          4: "8.0 x 8.0",
          5: "8.75 x 8.75",
          6: "9.25 x 9.25",
        };
      case "Princess":
      case "Asscher":
        return {
          1: "4.5 x 4.5",
          1.5: "5.25 x 5.25",
          2: "5.5 x 5.5",
          2.5: "6.0 x 6.0",
          3: "6.5 x 6.5",
          4: "7.0 x 7.0",
          5: "7.5 x 7.5",
          6: "8.0 x 8.0",
        };
      default:
        return {
          1: "5.0 x 5.0",
          1.5: "5.75 x 5.75",
          2: "6.5 x 6.5",
          2.5: "6.75 x 6.75",
          3: "7.25 x 7.25",
          4: "8.2 x 8.2",
          5: "8.75 x 8.75",
          6: "9.25 x 9.25",
        };
    }
  };

  return measurementChart(shape)[carat];
};

/**
 * Function to add business days
 * @param {date} d
 * @param {number} n
 * @return {date}
 */
export const addBusinessDays = (d, n) => {
  const currentDate = moment(d);
  const shipDate = business.addWeekDays(currentDate, n);
  return shipDate.toDate();
};

// eslint-disable-next-line valid-jsdoc
/**
 * Anti logrithmic function (for making big to small number)
 * @param {number} displayValue
 * @param {string} type
 * @return {number}
 */
export const toAntiLogarithmic = (displayValue, type) => {
  // Only for price filter
  const z = 1000000;
  const x = 0;
  const y = 10000;
  const a = (x * z - Math.pow(y, 2)) / (x - 2 * y + z);
  const b = Math.pow(y - x, 2) / (x - 2 * y + z);
  const c = 2 * Math.log((z - y) / (y - x));
  const returnedValue = Math.log((displayValue - a) / b) / c;
  return returnedValue > 1 && type == "max"
    ? 1
    : returnedValue >= 1 && type == "min"
    ? 0
    : returnedValue;
};

export const convertTZ = (date, tzString) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

/**
 * Logrithmic function (for making small to big number)
 * @param {number} sliderValue
 * @return {number}
 */
export const toLogarithmic = (sliderValue) => {
  // Only for price filters
  const x = 0;
  const y = 10000;
  const z = 1000000;
  const a = (x * z - Math.pow(y, 2)) / (x - 2 * y + z);
  const b = Math.pow(y - x, 2) / (x - 2 * y + z);
  const c = 2 * Math.log((z - y) / (y - x));

  // round off to nearest whole number
  const returnedValue = Math.round(a + b * Math.exp(c * sliderValue));
  return returnedValue === Infinity ? z : returnedValue;
};

/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info/
 *
 **/
export const Base64 = {
  // private property
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  // public method for encoding
  encode: function (input) {
    let output = "";
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }
    return output;
  },

  // public method for decoding
  decode: function (input) {
    let output = "";
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;

    // eslint-disable-next-line no-useless-escape
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }

    output = Base64._utf8_decode(output);

    return output;
  },

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    string = string.replace(/\r\n/g, "\n");
    let utftext = "";

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  },

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    let string = "";
    let i = 0;
    let c = 0;
    let c2 = 0;
    let c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }
    return string;
  },
};

const hoverImageKeys = ["YG-hover-image", "WG-hover-image", "RG-hover-image"];

const tagsKeys = [
  "persona",
  "setting",
  "stone",
  "wedding_ring",
  "custom_diamond",
  "engrave",
];

export const getFilteredMetafieldData = (
  storeFrontProductData,
  handle,
  images
) => {
  const products = storeFrontProductData?.products?.edges;
  const product = find(products, function (obj) {
    return obj?.node?.handle === handle;
  });

  const metafieldData = product?.node?.metafields?.edges;

  const hoverImages = {};
  for (let idx = 0; idx < metafieldData?.length; idx++) {
    const item = metafieldData[idx];
    if (hoverImageKeys.includes(item?.node?.key)) {
      const imageId = parseInt(item?.node.value);
      const image = find(images, ["id", imageId]);
      const key = split(item?.node.key, "-");
      hoverImages[key[0]] = image;
    }
  }
  const defaultMetal = find(metafieldData, function (obj) {
    return obj?.node?.key === "default_metal";
  });

  const ringDetails = {};
  const ringDetailsMeta = metafieldData?.filter((el) =>
    tagsKeys.includes(el.node.key)
  );
  forEach(ringDetailsMeta, (el) => {
    ringDetails[el.node.key] = el.node.value;
  });

  const metafields = {
    hoverImages,
    defaultMetal: defaultMetal?.node?.value,
    ringDetails,
  };
  return metafields;
};

export const getProductMetafieldData = (product, images) => {
  const metafieldData = product?.metafields;

  const hoverImages = {};
  for (let idx = 0; idx < metafieldData?.length; idx++) {
    const item = metafieldData[idx];
    if (hoverImageKeys.includes(item?.key)) {
      const imageId = parseInt(item?.value);
      const image = find(images, ["id", imageId]);
      const key = split(item?.key, "-");
      hoverImages[key[0]] = image;
    }
  }
  const defaultMetal = find(metafieldData, function (obj) {
    return obj?.key === "default_metal";
  });

  const ringDetails = {};
  const ringDetailsMeta = metafieldData?.filter((el) =>
    tagsKeys.includes(el.key)
  );
  forEach(ringDetailsMeta, (el) => {
    ringDetails[el.key] = el.value;
  });

  const metafields = {
    hoverImages,
    defaultMetal: defaultMetal?.value,
    ringDetails,
  };
  return metafields;
};

export const consentRequired = () => {
  // eslint-disable-next-line new-cap
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  switch (tz) {
    case "Europe/Vienna":
      return true;
    case "Europe/Brussels":
      return true;
    case "Europe/Sofia":
      return true;
    case "Europe/Zagreb":
      return true;
    case "Asia/Famagusta":
      return true;
    case "Asia/Nicosia":
      return true;
    case "Europe/Prague":
      return true;
    case "Europe/Copenhagen":
      return true;
    case "Europe/Tallinn":
      return true;
    case "Europe/Helsinki":
      return true;
    case "Europe/Paris":
      return true;
    case "Europe/Berlin":
      return true;
    case "Europe/Busingen":
      return true;
    case "Europe/Athens":
      return true;
    case "Europe/Budapest":
      return true;
    case "Europe/Dublin":
      return true;
    case "Europe/Rome":
      return true;
    case "Europe/Riga":
      return true;
    case "Europe/Vilnius":
      return true;
    case "Europe/Luxembourg":
      return true;
    case "Europe/Malta":
      return true;
    case "Europe/Amsterdam":
      return true;
    case "Europe/Warsaw":
      return true;
    case "Atlantic/Azores":
      return true;
    case "Atlantic/Madeira":
      return true;
    case "Europe/Lisbon":
      return true;
    case "Europe/Bucharest":
      return true;
    case "Europe/Bratislava":
      return true;
    case "Europe/Ljubljana":
      return true;
    case "Africa/Ceuta":
      return true;
    case "Atlantic/Canary":
      return true;
    case "Europe/Madrid":
      return true;
    case "Europe/Stockholm":
      return true;
    default:
      return false;
  }
};

export function getCookie(name) {
  function escape(s) {
    // eslint-disable-next-line no-useless-escape
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  const match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export async function slackNotifier(
  error_type,
  additional_info,
  slack_destination
) {
  // adding an env in production vercel to avoid all other environment calls
  if (process.env.NEXT_PUBLIC_SLACK_NOTIFIER_ENV !== "production") return;

  // allowing a new endpoint to be added, if not use the one in env
  const endpoint = () => {
    switch (slack_destination) {
      case "error_exception":
        return process.env.SLACK_ERROR_NOTIFIER_URL;
      case "product_exception":
        return process.env.SLACK_PRODUCT_ERROR_NOTIFIER_URL;
      case "summer_sale_exception":
        return process.env.SLACK_SUMMER_SALE_EXCEPTION_NOTIFIER_URL;
      default:
        return process.env.SLACK_ERROR_NOTIFIER_URL;
    }
  };

  await axios.post(endpoint(), {
    text: error_type,
    blocks: [
      {
        type: "section",
        block_id: "section789",
        fields: [
          {
            type: "mrkdwn",
            text: `${
              typeof error_type === "string"
                ? error_type
                : "Uncaught Exception:```" +
                  JSON.stringify(error_type, null, 2) +
                  "```"
            }\n`,
          },
        ],
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "*Additional Info:*\n```" +
            JSON.stringify(additional_info, null, 2) +
            "```",
        },
      },
    ],
  });
}

export const isInUsa = (location) => {
  const usaLocations = [
    "USA",
    "NEW YORK",
    "NEWYORK",
    "TRANSIT-NY",
    "NYC",
    "US",
    "JCK Las Vegas",
    "Los Angeles",
    "NY",
    "Chicago",
    "FDNY",
    "FDDC",
    "FDCH",
    "FDSF",
    "Maryland",
  ];

  const locationMatches = usaLocations.filter((element) => {
    return element?.toLowerCase() === location?.toLowerCase();
  });

  return locationMatches?.length > 0;
};

export const isInIndia = (location) => {
  const usaLocations = [
    "MUMBAI",
    "IND",
    "ind",
    "INDIA",
    "India",
    "SURAT",
    "IND - TRANSIT",
    "INDIA - IMAGE",
  ];

  const locationMatches = usaLocations.filter((element) => {
    return element?.toLowerCase() === location?.toLowerCase();
  });

  return locationMatches?.length > 0;
};

export const getOrdinal = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const sanitize = (obj) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? undefined : value;
    })
  );
};

export const changeDataKey = (dataToChange) => {
  const diamondData = {
    Shape: dataToChange["shape"],
    id: dataToChange["diamond_id"],
    Diamond_ID: dataToChange["diamond_id"],
    Carat: dataToChange["carat"],
    Cut_Grade: dataToChange["cut_grade"],
    Color_Name: dataToChange["color_name"],
    Clarity: dataToChange["clarity"],
    Type: dataToChange["type"],
    Cost: dataToChange["cost"],
    Color: dataToChange["color_name"],
    "Depth Per": dataToChange["depth_per"],
    Depth: dataToChange["depth"],
    Length: dataToChange["length"],
    Width: dataToChange["width"],
    Ratio: dataToChange["ratio"],
    "Supplier Id": dataToChange["supplier_id"],
    Location: dataToChange["location"],
    "See In Person": dataToChange["see_in_person"],
    "Certificate Url": dataToChange.certificate_url,
  };
  return diamondData;
};

export const bandMetals = {
  YG: "Yellow Gold",
  WG: "White Gold",
  RG: "Rose Gold",
  PL: "Platinum",
};

export const metalShortForm = {
  "Yellow Gold": "YG",
  "White Gold": "WG",
  "Rose Gold": "RG",
  Platinum: "PL",
};

export class Logger {
  static print = (...inputs) => console.log("LOGGER:", ...inputs);
}

export const isCustomDiamond = (diamondId) => {
  return defaultDiamondData.filter((el) => el.Id === diamondId).length;
};

// function to split a word into two lines as send it as an array
export const breakIntoTwoLines = (word) => {
  const split = word.split(" ");
  // get the median
  const splitLength = Math.ceil(split.length / 2);

  // divide it into two arrays
  return [split.splice(0, splitLength).join(" "), split.join(" ")];
};

export const capitalizeFirstLetter = (slug) => {
  if (slug.includes("-")) {
    const words = slug.split("-");
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(" ");
  } else {
    return slug.charAt(0).toUpperCase() + slug.slice(1);
  }
};

export const formatProductReviews = (reviews, productName) => {
  const firstThreeReviews = reviews?.slice(0, 3);
  const formattedReviews = firstThreeReviews?.map(
    ({ reviewer, date, review, rating }) => ({
      "@type": "Review",
      author: {
        "@type": "Person",
        name: reviewer.length > 0 ? reviewer : "Unknown",
      },
      datePublished: date?.split("T")[0],
      name: productName,
      reviewBody: review,
      reviewRating: {
        "@type": "Rating",
        ratingValue: rating,
      },
    })
  );
  return formattedReviews;
};

const customCollections = {
  "Lab Diamond": 424376565999,
  "FD Owned": 424376500463,
  "Guaranteed Brilliant": 424376533231,
};

const getCollectData = (collectionName, productId) => ({
  collect: {
    product_id: productId,
    collection_id: customCollections[collectionName],
  },
});

export const pushProductToCollection = async (diamondData, productId) => {
  let collections = [];
  if (diamondData["Type"] === "Lab") {
    collections = [getCollectData("Lab Diamond", productId)];
  }

  if (
    diamondData["Supplier ID"] === "FD" &&
    diamondData["Type"] !== "Moissanite"
  ) {
    collections = [...collections, getCollectData("FD Owned", productId)];
  }

  if (diamondData["Cut Grade"] === "Guaranteed Brilliant") {
    collections = [
      ...collections,
      getCollectData("Guaranteed Brilliant", productId),
    ];
  }

  if (collections.length > 0) {
    await Promise.all(
      collections.map((collectObj) => addProductToCustomCollection(collectObj))
    );
  }
};

const getUserLocation = () => {
  const { userCurrentLocation } = getCookies("userCurrentLocation");
  const locationParts = userCurrentLocation?.split("%7C");
  // uncomment this code to get city and country of the user.
  // const city = locationParts[0].split("City")[1];
  // const country = locationParts[1].split("Country")[1];
  const latitude = locationParts && locationParts[2]?.split("Latitude")[1];
  const longitude = locationParts && locationParts[3]?.split("Longitude")[1];

  return { latitude: Number(latitude), longitude: Number(longitude) };
};

export const getNearestLocation = () => {
  const { latitude, longitude } = getUserLocation();
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };
  const getDistance = (lat1, lng1, lat2, lng2) => {
    const earthRadius = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLng = deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in km
    return distance;
  };
  if (!isNaN(latitude) && !isNaN(longitude)) {
    const distances = locationArr.map((location) => ({
      ...location,
      distance: getDistance(
        latitude,
        longitude,
        location.latitude,
        location.longitude
      ),
    }));

    // Sorting locations by distance
    distances.sort((a, b) => a.distance - b.distance);

    // Return the nearest 3 locations
    return distances.slice(0, 3);
  }
  return null;
};

export const getInitialLocation = (router) => {
  const { query } = router;
  const isWindow = typeof window !== "undefined";

  if (query?.scroll_target && query?.location) {
    isWindow &&
      window.sessionStorage.setItem("fd_current_studio", query?.location);
    return query?.location;
  }

  if (isWindow && window.sessionStorage.getItem("fd_current_studio")) {
    return window.sessionStorage.getItem("fd_current_studio");
  }

  return getNearestLocation()?.[0]?.location ?? locationArr[0].location;
};

export const utmParamString = (utmParams) => {
  return Object?.keys(utmParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(utmParams[key])}`
    )
    .join("&");
};

export const truncateWordsFromString = (str, maxLength) => {
  const words = str.split(" ");
  const truncatedDescription = words.slice(0, maxLength).join(" ");
  return truncatedDescription;
};

export const getUtmParams = () =>
  typeof window !== "undefined" &&
  JSON.parse(window.sessionStorage.getItem("utmParams"));

export const removeNullValuesFromObj = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value != null)
  );

export const getRakutenParams =
  typeof window !== "undefined" &&
  JSON.parse(window.sessionStorage.getItem("rakutenParams"));

// ROW user should be showed different prices
export const isROWUser = (query) => {
  if (query) {
    const { source } = query;
    if (source === "fd-sales-team") return true;
  }

  // get user's location
  const { userCurrentLocation } = getCookies("userCurrentLocation");
  const locationParts = userCurrentLocation?.split("%7C");
  // eslint-disable-next-line no-unused-vars
  const country = locationParts && locationParts[1].split("Country")[1];

  // return country == "US" ? false : true;
  return false;
};

export const getPaddingValue = (dialogType, isWindow, scrollY) => {
  const isScrolled = isWindow && scrollY > 60;
  const isHolidayHeader = dialogType?.header && dialogType?.holidayHeader;
  const isHeader = dialogType?.header;

  if (isHolidayHeader) {
    return isScrolled
      ? "pt-16 md:pt-[124px] lg:pt-[107px]"
      : "pt-16 md:pt-32 lg:pt-44";
  }

  if (isHeader) {
    return isScrolled
      ? "pt-12 md:pt-[105px] lg:pt-[90px]"
      : "pt-12 md:pt-[105px] lg:pt-[152px]";
  }

  return isScrolled ? "pt-12 md:pt-16 lg:pt-12" : "pt-12 md:pt-16 lg:pt-28";
};

export const filterBlogsBySearch = (post, search) => {
  if (search.trim().length) {
    if (lowerCase(post.title).includes(search)) {
      return true;
    } else if (post?.tags.includes(search)) {
      return true;
    } else return false;
  } else return true;
};

// number of days for usual shipping
export const defaultShipDays = (type) => {
  switch (type) {
    case "ring":
    case "Wedding Ring":
    case "stud":
    case "Stud Earrings":
    case "necklace":
    case "Pendant Necklace":
    case "bracelet":
    case "Tennis Bracelet":
    case "Engagement Ring":
      return 14;

    default:
      return 14;
  }
};

// number of days for quick shipping
export const quickShipDays = (type) => {
  switch (type) {
    case "ring":
    case "Wedding Ring":
    case "Engagement Ring":
      return 10;

    case "stud":
    case "Stud Earrings":
      return 5;

    case "bracelet":
    case "Tennis Bracelet":
      return 2;

    case "necklace":
    case "Pendant Necklace":
      return 14;

    default:
      return 14;
  }
};

// number of days for diamonds shipping
export const diamondShipDays = (diamondData) =>
  isInUsa(diamondData["Location"])
    ? 5
    : isInIndia(diamondData["Location"])
    ? 21
    : 10;

export const persistCurrentLocation = (location) => {
  typeof window !== "undefined" &&
    window.sessionStorage.setItem("fd_current_studio", location);
};

export const getTopOffset = (route, dialogType, navData) => {
  const engagementSlug = navData
    .find(({ heading }) => heading === "Engagement")
    .subSections.filter((section) => section.subHeading === "by Shape")
    .reduce(
      (acc, section) => (
        section.links.forEach((link) =>
          !link.link.includes("?") ? acc.push(link.link + "/") : null
        ),
        acc
      ),
      []
    );
  const requiredRoutes = [
    "/",
    "/engagement-rings/",
    ...engagementSlug,
    "/wedding-rings/",
    "/necklaces/diamond-pendant-necklace/",
    "/bracelets/tennis-bracelets/",
    "/earrings/diamond-stud-earrings/",
  ];

  if (
    requiredRoutes.includes(
      route.includes("?") ? route.split("?")[0] : route
    ) &&
    dialogType.header
  ) {
    return dialogType.holidayHeader ? "top-16" : "top-10";
  }

  return "top-0";
};
