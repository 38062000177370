import { useContext } from "react";
import { SnackbarContext } from "../providers/snackbarProvider";

/**
 * Hooks for snackbar
 * @return {object}
 */
export default function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a snackProvider");
  }
  return context;
}
