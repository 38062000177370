import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { getWindowDimensions } from "../_helpers/functions";

export const DocumentContext = createContext({});

const DocumentProvider = ({ children }) => {
  const [screen, setScreen] = useState(null);
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  //

  const handleResize = () => {
    if (typeof window === `undefined`) {
      return;
    }

    let detectedScreen = `sm`;

    if (window.matchMedia(`(min-width: 640px`).matches) {
      detectedScreen = `sm`;
    }

    if (window.matchMedia(`(min-width: 768px`).matches) {
      detectedScreen = `md`;
    }

    if (window.matchMedia(`(min-width: 1024px)`).matches) {
      detectedScreen = `lg`;
    }

    if (window.matchMedia(`(min-width: 1280px`).matches) {
      detectedScreen = `xl`;
    }

    setScreen(detectedScreen);
    setWindowHeight(getWindowDimensions().height);
    setWindowWidth(getWindowDimensions().width);
  };

  const isDesktop = () => screen !== `sm` && screen !== `md`;

  //

  useEffect(() => {
    if (typeof window !== `undefined` && window?.addEventListener) {
      window.addEventListener(`resize`, _.throttle(handleResize), false);

      handleResize();
    }
  }, []);

  return (
    <DocumentContext.Provider
      value={{
        isDesktop,
        screen,
        windowHeight,
        windowWidth,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

DocumentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DocumentProvider;
