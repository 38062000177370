import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import reduce from "lodash/reduce";
import filter from "lodash/filter";
import remove from "lodash/remove";
import Link from "next/link";
import { useState, createContext, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import queryString from "query-string";
import useSnackbar from "../hooks/useSnackbar";
import useDialog from "../hooks/useDialog";
import { WishlistHeart } from "../_helpers/Icons/headerIcons";
import { getRakutenParams } from "../_helpers/functions";

const defaultFave = {
  rings: [],
  diamonds: [],
  necklaces: [],
  studs: [],
  bracelets: [],
};

// get the total length of the nested fave object
const getNestedSum = (arr) => {
  let sum = 0;

  // eslint-disable-next-line no-unused-vars
  for (const [key, val] of Object.entries(arr)) {
    sum += val.length;
  }

  return sum;
};

export const FaveContext = createContext();
/* eslint-disable react/prop-types */
/**
 * All Fave state
 * @return {node}
 */
export default function FaveProvider({ children }) {
  const [fave, updateFave] = useState(defaultFave);
  const [totalItems, setTotalItems] = useState(0);
  const { snackbar } = useSnackbar();
  const { dialogType } = useDialog();
  const router = useRouter();

  useEffect(() => {
    const stateFromStorage = window.localStorage.getItem("frankdarling-fave");
    const data = stateFromStorage && JSON.parse(stateFromStorage);

    if (
      !isEmpty(data) &&
      isArray(data?.rings) &&
      isArray(data?.diamonds) &&
      isArray(data?.necklaces) &&
      isArray(data?.studs) &&
      isArray(data?.bracelets)
    ) {
      updateFave(data);
    } else {
      updateFave(defaultFave);
    }
  }, []);

  useEffect(() => {
    const data = JSON.stringify(fave);
    window.localStorage.setItem("frankdarling-fave", data);
    const reducer = (total, item) => {
      if (!isObject(item)) {
        return ++total;
      }
      return total;
    };
    const totalRings = reduce(fave.rings, reducer, 0);
    const totalDiamonds = reduce(fave.diamonds, reducer, 0);
    const totalNecklaces = reduce(fave.necklaces, reducer, 0);
    const toaltStuds = reduce(fave.studs, reducer, 0);
    const totalBracelets = reduce(fave.bracelets, reducer, 0);
    const totals =
      Number(totalRings) +
      Number(totalDiamonds) +
      Number(toaltStuds) +
      Number(totalNecklaces) +
      Number(totalBracelets);
    setTotalItems(totals);
  }, [fave]);

  // creating a common const to call the snackbar
  const commonSnackbar = (title) => {
    return snackbar({
      message: title,
      variant: "success",
      icon: <WishlistHeart className="w-8 h-6 text-white" />,
      customElementRow: (
        <div className="flex justify-center mt-2.5">
          <Link
            legacyBehavior
            href={`/wishlist?${queryString.stringify({
              ...(dialogType.bottom && router.query && router.query.ranMID
                ? getRakutenParams
                : {}),
            })}`}
          >
            <a className="underline capitalize">View your wishlist</a>
          </Link>
        </div>
      ),
    });
  };

  /**
   * Function to add product to wishlist
   * @param {object} product object of product and type
   */
  function addToFave({ product, type }) {
    // Not to add diamond if in wishlist if not in stock
    if (
      product.archived ||
      product.soldAt ||
      product.Archived ||
      product["Sold At"]
    ) {
      commonSnackbar("Diamond Not Available");
      return;
    }

    // restrict the wishlist limit to 25 items
    if (getNestedSum(fave) >= 25) {
      commonSnackbar("Only 25 Items are allowed");
      return;
    }

    updateFave((prev) => {
      const fave = { ...prev };
      if (type === "necklace") {
        fave.necklaces.push(product?.id);
      } else if (type === "ring") {
        fave.rings.push(product?.id);
      } else if (type === "diamond") {
        fave.diamonds.push(product?.id || product?.Id);
      } else if (type === "stud") {
        fave.studs.push(product?.id);
      } else if (type === "bracelet") {
        fave.bracelets.push(product?.id);
      }
      return fave;
    });
    // here we use toast
    commonSnackbar("ADDED TO WISHLIST");
  }
  /**
   * Function to check if item is already in wishlist
   * @param {int} id
   * @return {boolean}
   */
  function isAddedToFave({
    ringId,
    diamondId,
    necklaceId,
    studId,
    braceletId,
  }) {
    if (necklaceId) {
      return filter(fave.necklaces, (el) => el === necklaceId).length > 0;
    } else if (ringId) {
      return filter(fave.rings, (el) => el === ringId).length > 0;
    } else if (diamondId) {
      return filter(fave.diamonds, (el) => el === diamondId).length > 0;
    } else if (studId) {
      return filter(fave.studs, (el) => el === studId).length > 0;
    } else if (braceletId) {
      return filter(fave.bracelets, (el) => el === braceletId).length > 0;
    }
  }

  /**
   * Function to remove item from cart
   * @param {object} param0
   */
  function removeFromFave({
    ringId,
    diamondId,
    necklaceId,
    studId,
    braceletId,
  }) {
    updateFave((prev) => {
      const fave = { ...prev };
      if (necklaceId) {
        remove(fave.necklaces, (el) => el === necklaceId);
      } else if (ringId) {
        remove(fave.rings, (el) => el === ringId);
      } else if (diamondId) {
        remove(fave.diamonds, (el) => el === diamondId);
      } else if (studId) {
        remove(fave.studs, (el) => el === studId);
      } else if (braceletId) {
        remove(fave.bracelets, (el) => el === braceletId);
      }
      return fave;
    });
  }

  const value = {
    fave,
    addToFave,
    isAddedToFave,
    removeFromFave,
    totalItems,
    updateFave,
  };

  return <FaveContext.Provider value={value}>{children}</FaveContext.Provider>;
}

/**
 * return wishlist object
 * @return {object}
 */
export function useFave() {
  const fave = useContext(FaveContext);
  if (fave === undefined) {
    throw new Error("useFave must be used within a FaveProvider");
  }
  return fave;
}
