import axios from "axios";

axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    if (["/login"].indexOf(config.url) === -1) {
      // pass token for user
      //   const token = CurrentUser.getToken();
      //   if (token) config.headers["Access-Token"] = token;
    } else {
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* eslint-disable-next-line */
function makeReq(method, url, data, config) {
  const promiseResponse = { error: null, data: null };
  const onSuccess = (response) => {
    promiseResponse.data = response.data;
    return Promise.resolve(promiseResponse);
  };

  const onError = (error) => {
    console.log("Request Failed:", error);
    const { response } = error;
    if (response) {
      const { data } = response;
      if (data.status == 500) {
        promiseResponse.error = {
          message: "Unable to connect with server. Try again after sometime",
        };
      } else {
        promiseResponse.error = { message: data.message };
      }
      return Promise.resolve(promiseResponse);
    } else {
      const { message } = error;
      promiseResponse.error = { message };
      return Promise.resolve(promiseResponse);
    }
  };
  return axios({
    method,
    url,
    data,
  })
    .then(onSuccess)
    .catch(onError);
}

const API = {
  post: (url, data) => makeReq("post", url, data),
  get: (url, data) => makeReq("get", url, data),
  put: (url, data) => makeReq("put", url, data),
  delete: (url, data) => makeReq("delete", url, data),
};

export default API;
