import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { consentRequired, getCookie } from "../_helpers/functions";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [resultCount, setResultCount] = useState(null);
  const [allowCookies, setAllowCookies] = useState(false);

  const getBoolean = (value) => {
    return value.toLowerCase() === "true";
  };

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== "undefined" || typeof window !== undefined) {
      const stateFromStorage = window.localStorage.getItem(
        "frankdarling-cookie"
      );
      const data = stateFromStorage && JSON.parse(stateFromStorage);
      const optOut = getCookie("OneTrustWPCCPAGoogleOptOut");
      const state =
        optOut !== null
          ? !getBoolean(optOut)
          : !consentRequired()
          ? true
          : data?.["set-cookie"] === "allow"
          ? true
          : false;
      setAllowCookies(state);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        menuActive,
        setMenuActive,
        resetSearch,
        setResetSearch,
        resultCount,
        setResultCount,
        allowCookies,
        setAllowCookies,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
