import join from "lodash/join";
import map from "lodash/map";
import compact from "lodash/compact";
import find from "lodash/join";
import isObject from "lodash/isObject";
import API, { SHOPIFY_API_VERSION } from "./API";
import {
  convertStoreFrontProductToAdminModel,
  shopifyRingComposer,
} from "./dataConverter";
import { gql } from "@apollo/client";
import StoreFrontAPI, {
  GET_PRODUCT_BY_HANDLE,
  GET_PRODUCTS,
  GET_ALL_PRODUCTS_BY_TYPE,
} from "./storefront";

export const getCollections = async () => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/custom_collections.json`
  );
};

export const addProductToCustomCollection = async (data) => {
  return await API.post(
    `/admin/api/${SHOPIFY_API_VERSION}/collects.json`,
    data
  );
};

export const getHomeCollectionProducts = async () => {
  const { data, error } = await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/collections/294930186440/products.json?limit=4`
  );
  if (error) return null;
  const ids = join(map(data.products, "id"), ",");
  return ids;
};

export const getHomeCollections = async () => {
  const ids = await getHomeCollectionProducts();
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?status=active&ids=${ids}`
  );
};

export const getSearchCollectionProducts = async () => {
  const { data, error } = await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/collections/414831804655/products.json`
  );
  if (error) return null;
  const ids = join(map(data.products, "id"), ",");
  return ids;
};

export const getSearchCollection = async () => {
  const ids = await getSearchCollectionProducts();
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?status=active&ids=${ids}`
  );
};

export const getEngagementRings = async () => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?product_type=Engagement%20Ring&limit=250`
  );
};

export const getProduct = async (productName) => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?handle=${productName}`
  );
};

export const getShopifyMetafields = async (productId) => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products/${productId}/metafields.json`
  );
};

export const getWeddingRings = async () => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?status=active&product_type=Wedding%20Ring&limit=250`
  );
};

export const getStudsEarrings = async () => {
  return await API.get(
    `/admin/api/2021-04/products.json?status=active&product_type=Stud%20Earrings&limit=250`
  );
};

export const getTennisBracelets = async () => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?status=active&product_type=Tennis%20Bracelet&limit=250`
  );
};

export const getProductInfo = async (productId) => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json?title=${productId}`
  );
};

export const createProduct = async (data) => {
  return await API.post(
    `/admin/api/${SHOPIFY_API_VERSION}/products.json`,
    data
  );
};

export const createProductImage = async (id, data) => {
  return await API.post(
    `/admin/api/${SHOPIFY_API_VERSION}/products/${id}/images.json`,
    data
  );
};

export const updateVariant = async (id, data) => {
  return await API.put(
    `/admin/api/${SHOPIFY_API_VERSION}/variants/${id}.json`,
    data
  );
};

export const updateProduct = async (id, data) => {
  return await API.put(
    `/admin/api/${SHOPIFY_API_VERSION}/products/${id}.json`,
    data
  );
};

export const deleteProduct = async (id) => {
  return await API.delete(
    `/admin/api/${SHOPIFY_API_VERSION}/products/${id}.json`
  );
};

// get price rule data
export const getPriceRule = async (id) => {
  return await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/price_rules/${id}.json`
  );
};

// update price rule data
export const updatePriceRule = async (id, data) => {
  return await API.put(
    `/admin/api/${SHOPIFY_API_VERSION}/price_rules/${id}.json`,
    { price_rule: data }
  );
};
export const getAllRings = async () => {
  const { data: weddingRingData, error: weddingRingError } =
    await getWeddingRings();
  const { data: engagementRingData, error: engagementRingError } =
    await getEngagementRings();
  if (engagementRingError || weddingRingError) {
    return null;
  }

  return [
    ...map(weddingRingData.products, shopifyRingComposer),
    ...map(engagementRingData.products, shopifyRingComposer),
  ];
};

export const getCollection = async (collectionId) => {
  const { data, error } = await API.get(
    `/admin/api/${SHOPIFY_API_VERSION}/collections/${collectionId}/products.json?limit=250`
  );
  if (error) return null;
  return data?.products;
};

export const getAllEngagementRings = async () => {
  const { data: engagementRingData, error: engagementRingError } =
    await getEngagementRings();
  if (engagementRingError) {
    return null;
  }

  const formattedData = [
    ...map(engagementRingData.products, shopifyRingComposer),
  ];

  const ringsCollection = await getCollection(396719423727);

  const products = [
    ...map(ringsCollection, (el) => {
      const data = find(formattedData, ["id", el?.id]);
      if (data !== undefined) {
        return data;
      }
      return null;
    }),
  ];
  return compact(products);
};

export const getDiamondCsv = async () => {
  return await API.graph(
    gql`
      {
        allDiamonds {
          csvFile {
            asset {
              url
            }
          }
        }
      }
    `
  );
};

export const getProductFromStoreFront = async ({ handle }) => {
  const response = await StoreFrontAPI.query(GET_PRODUCT_BY_HANDLE, { handle });
  if (response?.data?.product) {
    const product = convertStoreFrontProductToAdminModel(
      response?.data?.product
    );
    return product;
  }
  return null;
};

export const getProductsFromStoreFront = async (ids) => {
  const gids = ids
    ?.filter((id) => !isObject(id))
    ?.map((id) => {
      return btoa(`gid://shopify/Product/${id}`);
    });
  const formattedIds = JSON.stringify(gids);

  // eslint-disable-next-line new-cap
  const response = await StoreFrontAPI.query(GET_PRODUCTS(formattedIds));

  const formattedData = response?.data?.nodes?.map((product) => {
    const dataInAdminModel = convertStoreFrontProductToAdminModel(product);
    return shopifyRingComposer(dataInAdminModel);
  });
  return compact(formattedData);
};

export const getStoreFrontProductData = async ({ productType }) => {
  const response = await StoreFrontAPI.query(
    // eslint-disable-next-line new-cap
    GET_ALL_PRODUCTS_BY_TYPE(productType)
  );
  if (response?.data?.products) {
    const products = response?.data?.products;
    return products;
  }
  return null;
};
