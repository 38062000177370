import axios from "axios";
import { ApolloClient, InMemoryCache } from "@apollo/client";

export const SHOPIFY_API_VERSION = "2022-10";
export const GRAPHQL_STOREFRONT_API_VERSION = "2023-07";

const client = new ApolloClient({
  uri: `https://${process.env.SANITY_PROJECT_ID}.api.sanity.io/v1/graphql/production/default`,
  cache: new InMemoryCache(),
  ssrMode: true,
});

axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    if (["/login"].indexOf(config.url) === -1) {
      // pass token for user
      //   const token = CurrentUser.getToken();
      //   if (token) config.headers["Access-Token"] = token;
    } else {
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* eslint-disable-next-line */
function makeReq(method, url, data, config) {
  axios.defaults.baseURL = `https://${process.env.SHOPIFY_USERNAME}:${process.env.SHOPIFY_PASSWORD}@${process.env.SHOPIFY_STORE}`;

  const promiseResponse = { error: null, data: null };
  const onSuccess = (response) => {
    // console.log("Request Successful!", response);
    promiseResponse.data = response.data;
    return Promise.resolve(promiseResponse);
  };

  const onError = (error) => {
    console.log("Request Failed:", error);
    const { response } = error;
    if (response) {
      const { data } = response;
      // console.log("Data:", data);
      if (data.status === "500") {
        promiseResponse.error = {
          message: "Unable to connect with server. Try again after sometime",
        };
      } else if (data.status === 401) {
        // logout user
        // CurrentUser.logout();
        promiseResponse.error = {
          message: "Unauthorized user",
        };
        window.location.href = "/login";
      } else {
        promiseResponse.error = { message: data.errors };
      }
      return Promise.reject(promiseResponse);
    } else {
      const { message } = error;
      promiseResponse.error = { message };
      return Promise.reject(promiseResponse);
    }
  };
  return axios({
    method,
    url,
    data,
  })
    .then(onSuccess)
    .catch(onError);
}

/* eslint-disable-next-line */
function makeGraphReq(query, variables) {
  const promiseResponse = { error: null, data: null };
  const onSuccess = (response) => {
    promiseResponse.data = response.data;
    return Promise.resolve(promiseResponse);
  };
  const onError = (error) => {
    console.log("Request Failed:", error);
    /* eslint-disable-next-line */
    const { response } = error;
    const { message } = error;
    promiseResponse.error = { message };
    return Promise.resolve(promiseResponse);
  };
  return client
    .query({
      query,
    })
    .then(onSuccess)
    .catch(onError);
}

const API = {
  post: (url, data) => makeReq("post", url, data),
  get: (url, data) => makeReq("get", url, data),
  put: (url, data) => makeReq("put", url, data),
  delete: (url, data) => makeReq("delete", url, data),
  graph: (data) => makeGraphReq(data),
};

export default API;
