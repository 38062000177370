/* eslint-disable require-jsdoc */
import { createContext, useContext } from "react";
import firebase from "firebase/compat/app";
// (which uses Rest API, offers no cache or listeners and is much smaller)
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  setDoc,
} from "firebase/firestore/lite";

const clientCredentials = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

if (!firebase?.apps?.length) {
  firebase.initializeApp(clientCredentials);
}

export const db = getFirestore();
// export const cartRef = collection(db, "cart");
export const faveRef = collection(db, "fave");

export const FirebaseContext = createContext();

export async function getDocumentByUniqueId(docId) {
  // const cartDocRef = doc(db, "cart", docId);
  const faveDocRef = doc(db, "fave", docId);
  // const cartDocSnap = await getDoc(cartDocRef);
  const faveDocSnap = await getDoc(faveDocRef);
  if (
    faveDocSnap.exists()
    // && cartDocSnap.exists()
  ) {
    return {
      // cart: cartDocSnap.data(),
      fave: faveDocSnap.data(),
    };
  } else {
    throw Error("Doc does not exist");
  }
}

export async function syncDocumentByUniqueId(docId, data) {
  await setDoc(doc(db, "fave", docId), data);
}

/* eslint-disable react/prop-types */
/**
 * Firebase app state
 * @return {node}
 */
export default function FirebaseProvider({ children }) {
  return (
    <FirebaseContext.Provider value={{ firebase }}>
      {children}
    </FirebaseContext.Provider>
  );
}

/**
 * return firebase object
 * @return {object}
 */
export function useFirebase() {
  const compare = useContext(FirebaseContext);
  if (compare === undefined) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return compare;
}
