/* eslint-disable react/prop-types */
import React, { useMemo } from "react";

export const LocalePriceContext = React.createContext();

/**
 * Snackbar provider
 * @param {node} children Children to render
 * @return {node}
 */
function LocalePriceProvider({ currency, children }) {
  const value = useMemo(() => {
    const priceInLocalCurrency = (price) => {
      return price * currency.exchangeRate;
    };

    const formatPrice = (price, format = "{symbol}{price}", toFixed = 2) => {
      switch (format) {
        case "{symbol}{price}":
          return `${currency.currencySymbol}${priceInLocalCurrency(
            price
          ).toFixed(toFixed)}`;
        case "{iso} {symbol}{price}":
          return `${currency.currency} ${
            currency.currencySymbol
          }${priceInLocalCurrency(price).toFixed(toFixed)}`;
        default:
          return `${currency.currencySymbol}${priceInLocalCurrency(price)}`;
      }
    };
    const exchangeRate = currency?.exchangeRate ?? 1;
    const currencyISO = currency?.currency ?? "USD";
    const currencySymbol = currency?.currencySymbol ?? "$";

    return {
      exchangeRate,
      priceInLocalCurrency,
      currency: currencyISO,
      currencySymbol,
      formatPrice,
    };
  }, [currency]);
  return (
    <LocalePriceContext.Provider value={value}>
      {children}
    </LocalePriceContext.Provider>
  );
}

export default LocalePriceProvider;
