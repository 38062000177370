/* eslint-disable react/prop-types */
import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Toast from "../components/Toast";

export const SnackbarContext = React.createContext();

/**
 * Snackbar provider
 * @param {node} children Children to render
 * @return {node}
 */
function SnackbarProvider({ children }) {
  const [toast, setToast] = useState({});
  const snackbar = (toast) => {
    setToast("");
    setTimeout(() => {
      setToast(toast);
    }, 200);
  };

  return (
    <SnackbarContext.Provider value={{ snackbar, setToast }}>
      {children}
      <div>{!isEmpty(toast) && <Toast {...toast} />}</div>
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
