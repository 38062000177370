import _ from "lodash";
import isUndefined from "lodash/isUndefined";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";
import compact from "lodash/compact";
import map from "lodash/map";
import flatten from "lodash/flatten";
import findIndex from "lodash/findIndex";
import pick from "lodash/pick";
import keyBy from "lodash/keyBy";
import each from "lodash/each";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import forEach from "lodash/forEach";
import last from "lodash/last";
import snakeCase from "lodash/snakeCase";
import split from "lodash/split";
import startCase from "lodash/startCase";
import join from "lodash/join";
import { sanitize } from "../_helpers/functions";
import { DT_ND, DT_LGD, DT_RD } from "./constants";
import { PRODUCT_METAFIELD_KEYS } from "./storefront";

export const bareMetalNameToSymbol = {
  "18k yellow gold": "YG",
  "14k yellow gold": "YG",
  "18k white gold": "WG",
  "14k white gold": "WG",
  "18k rose gold": "RG",
  "14k rose gold": "RG",
  platinum: "PL",
};

export const bareDiamondNameToSymbol = {
  "Natural Diamonds": DT_ND,
  "Lab Grown Diamonds": DT_LGD,
  "Recycled Diamonds": DT_RD,
};

const imagesKey = ["YG-images", "WG-images", "RG-images"];

const mainPicks = [
  "title",
  "product_type",
  "created_at",
  "handle",
  "id",
  "hoverImages",
  "defaultMetal",
  "ringDetails",
  "metafields",
];

const variantPick = [
  "option1",
  "option2",
  "option3",
  "price",
  "inventory_policy",
  "inventory_quantity",
  "id",
  "sku",
];

// eslint-disable-next-line no-unused-vars
const splitTags = (str) => {
  return _.chain(str)
    .split(",")
    .map((s) => _.chain(s).replace(" ", "").split(":").value())
    .fromPairs()
    .value();
};

/**
 * Spliting tags into objects (if more than one strone then array)
 * @param {string} str
 * @return {object}
 */
const splitRingDetailsTags = (str) => {
  const tagArray = str
    .split(",")
    .map((s) => _.chain(s).replace(" ", "").split(":").value());
  const tagObjArr = tagArray.map((el) => {
    return { [el[0]]: el[1] };
  });
  const finalTagsObject = mergeWith({}, ...tagObjArr, (a, b) =>
    isUndefined(a) ? b : [a, b]
  );
  const stone = isArray(finalTagsObject.stone)
    ? compact(flatten(finalTagsObject.stone))
    : finalTagsObject.stone;
  return {
    ...finalTagsObject,
    stone: stone ?? null,
  };
};

export const findMetalType = (str) => {
  if (str.indexOf("White Gold") != -1) return "WG";
  if (str.indexOf("Yellow Gold") != -1) return "YG";
  if (str.indexOf("Rose Gold") != -1) return "RG";
  return false;
};

const getReplica = (objectValue, variant) => {
  const replicaMetal = findMetalType(variant.option1);
  const hasSameNormalMetal = findIndex(objectValue.variants, (el) => {
    const metalCode = bareMetalNameToSymbol[el.option1.toLowerCase()];
    const price = Number(el.price);
    if (metalCode === replicaMetal && price > 1) return true;
    return false;
  });
  // Pick those replica metal who have normal metal
  if (!replicaMetal || hasSameNormalMetal === -1) return;
  objectValue["replica_metal"][replicaMetal] = pick(variant, [
    "inventory_quantity",
    "inventory_policy",
    "price",
    "id",
    "title",
  ]);

  if (objectValue["replica_metal"].hasOwnProperty("WG")) {
    const hasNormalPl = findIndex(objectValue.variants, (el) => {
      const metalCode = bareMetalNameToSymbol[el.option1.toLowerCase()];
      const price = Number(el.price);
      if (metalCode === "PL" && price > 1) return true;
      return false;
    });
    if (hasNormalPl > -1) {
      objectValue["replica_metal"]["PL"] = objectValue["replica_metal"]["WG"];
    }
  }
  return null;
};

export const shopifyRingComposer = (p) => {
  const mainValue = pick(p, mainPicks);
  mainValue["small_description"] = p.body_html
    ?.substring(p.body_html.indexOf("<p>") + 3, p.body_html.indexOf("</p>"))
    .replace(/(<([^>]+)>)/gi, "")
    ?.replace(/^.+>/gi, "")
    ?.replace("<br>", "");
  if (!p.tags) return null;
  mainValue["tags"] = splitRingDetailsTags(p.tags);
  mainValue["options"] = map(p.options, (o) => {
    const { name, values } = o;
    return {
      name,
      values: values.filter((v) => v.indexOf("Replica") == -1),
    };
  });
  const variantImages = keyBy(p.images, "id");
  mainValue["variants"] = compact(
    map(p.variants, (v) => {
      const variantValue = pick(v, variantPick);
      variantValue["image"] = pick(variantImages[v.image_id] ?? p.image, [
        "src",
        "alt",
      ]);
      return variantValue;
    })
  );
  mainValue["images"] = p?.images;
  mainValue["replica_metal"] = {};
  each(p.variants, (v) => {
    if (v.price < 1) {
      getReplica(mainValue, v);
    }
  });

  // for specifically gift guide products
  if (p.custom_description)
    mainValue["custom_description"] = p.custom_description;
  return composeRingCardData(mainValue);
};

export const composeRingCardData = (product) => {
  const metalOption = product.options.find((el) => el.name === "Metal");

  // adding a misc check because we don't use metal option here
  if (product?.["product_type"] !== "Misc") {
    product["metal"] = _.chain(metalOption?.values)
      .map((v) => v.toLowerCase())
      .filter((v) => v.indexOf("replica") == -1)
      .map((v) => bareMetalNameToSymbol[v])
      .uniq()
      .value()
      .sort()
      .reverse();
  }
  const diamondOption = product.options.find(
    (el) => el.name === "Diamond Type"
  );
  product["setting_only"] = !diamondOption;
  product["variant_details"] = {};
  each(product.variants, (v) => {
    const metalCode = bareMetalNameToSymbol[v.option1.toLowerCase()];
    if (!product["variant_details"].hasOwnProperty(metalCode)) {
      product["variant_details"][metalCode] = pick(v, [
        "price",
        "image",
        "inventory_policy",
        "inventory_quantity",
        "sku",
      ]);
    } else {
      if (!product["variant_details"][metalCode]?.image?.src) {
        product["variant_details"][metalCode].image = v.image;
      }
      product["variant_details"][metalCode].price = Math.min(
        product["variant_details"][metalCode].price,
        v.price
      );
    }
  });
  return omit(product, ["options", "variants"]);
};

export const shopifyNecklaceComposer = (p) => {
  const mainValue = pick(p, mainPicks);
  mainValue["small_description"] = p?.body_html
    ?.substring(p.body_html.indexOf("<p>") + 3, p.body_html.indexOf("</p>"))
    .replace("<br>", "");
  if (!p.tags) return null;
  mainValue["tags"] = splitRingDetailsTags(p.tags);
  mainValue["options"] = map(p.options, (o) => {
    const { name, values } = o;
    return {
      name,
      values: values.filter((v) => v.indexOf("Replica") == -1),
    };
  });
  const variantImages = keyBy(p.images, "id");
  mainValue["variants"] = compact(
    map(p.variants, (v) => {
      const variantValue = pick(v, variantPick);
      variantValue["image"] = pick(variantImages[v.image_id] ?? p.image, [
        "src",
        "alt",
      ]);
      return variantValue;
    })
  );
  mainValue["images"] = p?.images;
  mainValue["replica_metal"] = {};
  each(p.variants, (v) => {
    if (v.price < 1) {
      getReplica(mainValue, v);
    }
  });
  return composeNecklaceCardData(mainValue);
};

export const composeNecklaceCardData = (product) => {
  const metalOption = product.options.find((el) => el.name === "Metal");
  product["metal"] = _.chain(metalOption.values)
    .map((v) => v.toLowerCase())
    .filter((v) => v.indexOf("replica") == -1)
    .map((v) => bareMetalNameToSymbol[v])
    .uniq()
    .value()
    .sort()
    .reverse();
  const diamondOption = product.options.find(
    (el) => el.name === "Diamond Type"
  );
  product["setting_only"] = !diamondOption;
  product["variant_details"] = {};
  each(product.variants, (v) => {
    const metalCode = bareMetalNameToSymbol[v.option1.toLowerCase()];
    // eslint-disable-next-line no-prototype-builtins
    if (!product["variant_details"].hasOwnProperty(metalCode)) {
      product["variant_details"][metalCode] = pick(v, [
        "price",
        "image",
        "inventory_policy",
        "inventory_quantity",
        "sku",
      ]);
    } else {
      if (!product["variant_details"][metalCode]?.image?.src) {
        product["variant_details"][metalCode].image = v.image;
      }
      product["variant_details"][metalCode].price = Math.min(
        product["variant_details"][metalCode].price,
        v.price
      );
    }
  });
  return omit(product, ["options", "variants"]);
};

export const shopifyRingDetailComposer = (product) => {
  const mainDetail = pick(product, mainPicks);
  mainDetail["description"] = product.body_html
    .substring(product.body_html.indexOf("</p>") + 5, product.body_html.length)
    .replace(/&amp;/g, "&")
    .replace(/\n/g, "");
  mainDetail["small_description"] = product.body_html
    .substring(
      product.body_html.indexOf("<p>") + 3,
      product.body_html.indexOf("</p>")
    )
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/&amp;/g, "&");
  const variantImages = keyBy(product.images, "id");
  mainDetail["variants"] = map(product.variants, (v) => {
    const variantValue = pick(v, variantPick);

    /** Adding image for cart purpose */
    variantValue["image"] = pick(variantImages[v.image_id] ?? product.image, [
      "src",
      "alt",
    ]);
    return variantValue;
  });
  mainDetail["metals"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[0];

  mainDetail["replica_metal"] = {};
  each(product.variants, (v) => {
    if (v.price < 1) {
      getReplica(mainDetail, v);
    }
  });

  const bandWidthObj = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  });

  const stoneTypeOptions = product.options.find(
    (el) => el.name === "Stone Type"
  )?.values;

  mainDetail["diamonds"] = uniq(
    compact(
      map(product.variants, (el) => {
        const { option2 } = el;
        return option2
          ? // allowing stone types for edge case compatibility
            option2.indexOf("Diamonds") !== -1 || !!stoneTypeOptions
            ? option2
            : false
          : false;
      })
    )
  );

  if (mainDetail["diamonds"].length === 0) {
    mainDetail["bandWidths"] = bandWidthObj[1];
  } else {
    mainDetail["bandWidths"] = bandWidthObj[2];
  }

  mainDetail["images"] = product.images;
  mainDetail["tags"] = splitRingDetailsTags(product.tags);
  return mainDetail;
};

export const shopifyNecklaceDetailComposer = (product) => {
  const mainDetail = pick(product, mainPicks);
  mainDetail["description"] = product.body_html
    .substring(product.body_html.indexOf("</p>") + 5, product.body_html.length)
    .replace(/&amp;/g, "&")
    .replace(/\n/g, "");
  mainDetail["small_description"] = product.body_html
    .substring(
      product.body_html.indexOf("<p>") + 3,
      product.body_html.indexOf("</p>")
    )
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/&amp;/g, "&");
  const variantImages = keyBy(product.images, "id");

  mainDetail["variants"] = map(product.variants, (v) => {
    const variantValue = pick(v, variantPick);

    /** Adding image for cart purpose */
    variantValue["image"] = pick(variantImages[v.image_id] ?? product.image, [
      "src",
      "alt",
    ]);
    return variantValue;
  });

  mainDetail["metals"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[0];

  mainDetail["replica_metal"] = {};
  each(product.variants, (v) => {
    if (v.price < 1) {
      getReplica(mainDetail, v);
    }
  });

  mainDetail["diamonds"] = uniq(
    compact(
      map(product.variants, (el) => {
        const { option2 } = el;
        return option2
          ? option2.indexOf("Diamonds") !== -1
            ? option2
            : false
          : false;
      })
    )
  );

  mainDetail["images"] = product.images;
  mainDetail["tags"] = splitRingDetailsTags(product.tags);

  return mainDetail;
};
export const shopifyStudsDetailComposer = (product) => {
  const mainDetail = pick(product, mainPicks);
  mainDetail["description"] = product.body_html
    .substring(product.body_html.indexOf("</p>") + 5, product.body_html.length)
    .replace(/&amp;/g, "&")
    .replace(/\n/g, "");
  mainDetail["small_description"] = product.body_html
    .substring(
      product.body_html.indexOf("<p>") + 3,
      product.body_html.indexOf("</p>")
    )
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/^.+>/gi, "")
    .replace(/&amp;/g, "&");
  const variantImages = keyBy(product.images, "id");

  mainDetail["variants"] = map(product.variants, (v) => {
    const variantValue = pick(v, variantPick);

    /** Adding image for cart purpose */
    variantValue["image"] = pick(variantImages[v.image_id] ?? product.image, [
      "src",
      "alt",
    ]);
    return variantValue;
  });

  mainDetail["metals"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[0];

  mainDetail["replica_metal"] = {};
  each(product.variants, (v) => {
    if (v.price < 1) {
      getReplica(mainDetail, v);
    }
  });

  mainDetail["carat_weights"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[1];

  mainDetail["stone_types"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1).reverse();
  })[2];

  mainDetail["images"] = product.images;
  mainDetail["tags"] = splitRingDetailsTags(product.tags);

  mainDetail["variants"].sort((a, b) => a.price - b.price);
  return mainDetail;
};

export const shopifyBraceletDetailComposer = (product) => {
  const mainDetail = pick(product, mainPicks);
  mainDetail["description"] = product.body_html
    .substring(product.body_html.indexOf("</p>") + 5, product.body_html.length)
    .replace(/&amp;/g, "&")
    .replace(/\n/g, "");
  mainDetail["small_description"] = product.body_html
    .substring(
      product.body_html.indexOf("<p>") + 3,
      product.body_html.indexOf("</p>")
    )
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/^.+>/gi, "")
    .replace(/&amp;/g, "&");
  const variantImages = keyBy(product.images, "id");

  mainDetail["variants"] = map(product.variants, (v) => {
    const variantValue = pick(v, variantPick);

    /** Adding image for cart purpose */
    variantValue["image"] = pick(variantImages[v.image_id] ?? product.image, [
      "src",
      "alt",
    ]);
    return variantValue;
  });

  mainDetail["metals"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[0];

  mainDetail["replica_metal"] = {};
  each(product.variants, (v) => {
    if (v.price < 1) {
      getReplica(mainDetail, v);
    }
  });

  mainDetail["total_length"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[1];

  mainDetail["stone_types"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1).reverse();
  })[2];

  mainDetail["images"] = product.images;
  mainDetail["tags"] = splitRingDetailsTags(product.tags);

  mainDetail["variants"].sort((a, b) => a.price - b.price);
  return mainDetail;
};

export const shopifyGiftCardDetailComposer = (product) => {
  const mainDetail = pick(product, mainPicks);
  mainDetail["description"] = product.body_html
    .substring(product.body_html.indexOf("</p>") + 5, product.body_html.length)
    .replace(/&amp;/g, "&")
    .replace(/\n/g, "");
  mainDetail["small_description"] = product.body_html
    .substring(
      product.body_html.indexOf("<p>") + 3,
      product.body_html.indexOf("</p>")
    )
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/^.+>/gi, "")
    .replace(/&amp;/g, "&");
  const variantImages = keyBy(product.images, "id");

  mainDetail["variants"] = map(product.variants, (v) => {
    const variantValue = pick(v, variantPick);

    /** Adding image for cart purpose */
    variantValue["image"] = pick(variantImages[v.image_id] ?? product.image, [
      "src",
      "alt",
    ]);
    return variantValue;
  });

  mainDetail["amount"] = map(product.options, (o) => {
    const { values } = o;
    return values.filter((v) => v.indexOf("Replica") == -1);
  })[0];

  mainDetail["images"] = product.images;
  mainDetail["tags"] = splitRingDetailsTags(product.tags);

  mainDetail["variants"].sort((a, b) => a.price - b.price);
  return mainDetail;
};

export const shopifyRingImageComposer = (ringData, metaFields) => {
  const result = {};
  const ringImages = ringData?.images;
  const isGiftCard = ringData.product_type === "Misc";
  const metaImages =
    !isGiftCard && metaFields.filter((el) => imagesKey.includes(el.key));

  const getVariantImages = (variant) => {
    const variantImageIds = variant.split(",");
    const images = [];
    variantImageIds?.map((item) => {
      const image = ringImages.find(function (o) {
        return String(o?.id) === String(item);
      });
      if (image !== undefined) {
        images.push(image);
      }
    });
    return images;
  };

  forEach(metaImages, (el) => {
    result[el.key] = getVariantImages(el.value);
  });
  return result;
};
export const shopifyNecklaceImageComposer = (necklaceData, metaFields) => {
  const result = {};
  const necklaceImages = necklaceData?.images;
  const metaImages = metaFields?.filter((el) => imagesKey.includes(el.key));

  const getVariantImages = (variant) => {
    const variantImageIds = variant.split(",");
    const images = [];
    variantImageIds?.map((item) => {
      const image = necklaceImages.find(function (o) {
        return String(o?.id) === String(item);
      });
      if (image !== undefined) {
        images.push(image);
      }
    });
    return images;
  };

  forEach(metaImages, (el) => {
    result[el.key] = getVariantImages(el.value);
  });

  return result;
};

export const shopifyRingAdditionalDetailsComposer = (metaFields) => {
  const result = {};
  const key = [
    "product",
    "profile",
    "stackable",
    "setting_height",
    "band_width",
    "band_height",
    "pave_carat_weight",
    "center_stone_carat_weight",
    "collection_name",
    "stone_size",
    "total_carat_weight",
    "pave_size",
    "side_stone_size",
    "side_stone_tcw",
    "side_stone_pave_quality_natural",
    "side_stone_pave_quality_lab",
    "resizing_tolerance",
    // Studs Keys
    "stone_clarity",
    "stone_color",
    "stone_size",
    "carat_weight",
    "closure",
    "posts",
    "chain_length",
    "chain_width",
    "chain_type",
  ];

  const additionalDetails = metaFields.filter((el) => key.includes(el.key));

  forEach(additionalDetails, (el) => {
    result[el.key] = el.value;
  });
  return result;
};
export const shopifyNecklaceAdditionalDetailsComposer = (metaFields) => {
  const result = {};
  const key = [
    "profile",
    "stackable",
    "setting_height",
    "band_width",
    "band_height",
    "pave_carat_weight",
    "center_stone_carat_weight",
    "chain_length",
    "chain_width",
    "chain_type",
    "closure_type",
  ];

  const additionalDetails = metaFields.filter((el) => key.includes(el.key));

  forEach(additionalDetails, (el) => {
    result[el.key] = el.value;
  });
  return result;
};

export const shopifyRingVideosComposer = (metaFields) => {
  const videos = {};
  const variants = ["YG", "RG", "WG"];
  variants.map((item) => {
    const value = [];
    const ringVideo = metaFields.find((el) => el.key === `${item}_video`);
    if (ringVideo !== undefined) {
      value.push(JSON.parse(ringVideo.value));
    }
    const handVideo = metaFields.find((el) => el.key === `${item}_hand_video`);
    if (handVideo !== undefined) {
      value.push(JSON.parse(handVideo.value));
    }
    videos[item] = value;
  });
  return videos;
};
export const shopifyNecklaceVideosComposer = (metaFields) => {
  const videos = {};
  const variants = ["YG", "RG", "WG"];
  variants.map((item) => {
    const value = [];
    const ringVideo = metaFields?.find((el) => el.key === `${item}_video`);
    if (ringVideo !== undefined) {
      value.push(JSON.parse(ringVideo.value));
    }
    videos[item] = value;
  });
  return videos;
};

export const extractIdFromGraphQL = (gqlId) => {
  const id = last(gqlId?.split("/"));
  const id_to_int = Number(id);
  return isNaN(id_to_int) ? id : id_to_int;
};

export const convertStoreFrontProductToAdminModel = (storeFrontProduct) => {
  let returnProduct = {};
  returnProduct = {
    id: extractIdFromGraphQL(storeFrontProduct?.id),
    title: storeFrontProduct?.title,
    body_html: storeFrontProduct?.descriptionHtml,
    vendor: storeFrontProduct?.vendor,
    product_type: storeFrontProduct?.productType,
    created_at: storeFrontProduct?.createdAt,
    handle: storeFrontProduct?.handle,
    updated_at: storeFrontProduct?.updatedAt,
    published_at: storeFrontProduct?.publishedAt,
    status: "active",
    tags: storeFrontProduct?.tags?.join(", "),
    variants: storeFrontProduct?.variants?.nodes?.map((variant, index) => ({
      id: extractIdFromGraphQL(variant?.id),
      product_id: extractIdFromGraphQL(storeFrontProduct?.id),
      title: variant?.title,
      price: variant?.priceV2?.amount,
      sku: variant?.sku,
      position: index + 1,
      inventory_policy: variant?.availableForSale ? "continue" : "deny",
      inventory_quantity: variant?.quantityAvailable,
      requiresShipping: variant?.requiresShipping,
      weight: variant?.weight,
      weight_unit: variant?.weightUnit,
      compare_at_price: variant?.compareAtPriceV2?.amount,
      option1: variant?.selectedOptions[0]?.value,
      option2: variant?.selectedOptions[1]?.value,
      option3: variant?.selectedOptions[2]?.value,
      image_id: extractIdFromGraphQL(variant?.image?.id),
    })),
    options: storeFrontProduct?.options?.map((option, index) => ({
      id: extractIdFromGraphQL(option?.id),
      product_id: extractIdFromGraphQL(storeFrontProduct?.id),
      name: option?.name,
      position: index + 1,
      values: option?.values,
    })),
    images: storeFrontProduct?.images?.nodes?.map((image, index) => ({
      id: extractIdFromGraphQL(image?.id),
      product_id: extractIdFromGraphQL(storeFrontProduct?.id),
      position: index + 1,
      alt: image?.altText,
      width: image?.width,
      height: image?.height,
      src: image?.url,
    })),
    image: {
      id: extractIdFromGraphQL(storeFrontProduct?.featuredImage?.id),
      product_id: extractIdFromGraphQL(storeFrontProduct?.id),
      alt: storeFrontProduct?.featuredImage.altText,
      width: storeFrontProduct?.featuredImage.width,
      height: storeFrontProduct?.featuredImage.height,
      src: storeFrontProduct?.featuredImage.url,
    },
    metafields: PRODUCT_METAFIELD_KEYS.map((meta) => {
      if (storeFrontProduct?.[`${snakeCase(meta)}`] == null) {
        return {};
      } else {
        const metafield = storeFrontProduct?.[snakeCase(meta)];
        return {
          id: extractIdFromGraphQL(metafield?.id),
          namespace: metafield?.metafield,
          key: metafield?.key,
          value: metafield?.value,
          description: metafield?.description,
          owner_id: extractIdFromGraphQL(storeFrontProduct?.id),
          created_at: metafield?.createdAt,
          updated_at: metafield?.updatedAt,
          owner_resource: "product",
          type: metafield?.type,
          admin_graphql_api_id: metafield?.id,
        };
      }
    }),
  };
  return sanitize(returnProduct);
};

const getStoneValue = (stoneValues) => {
  const values = split(stoneValues, ",");
  const caseConverted = map(values, (value) => startCase(value));
  return join(caseConverted, ",");
};

export const ringDetailsComposer = (metaFields) => {
  const result = {};
  const key = [
    "persona",
    "setting",
    "stone",
    "wedding_ring",
    "custom_diamond",
    "engrave",
    "seo_name",
    "seo_description",
  ];
  const ringDetails = metaFields?.filter((el) => key.includes(el.key));
  forEach(ringDetails, (el) => {
    result[el.key === "engrave" ? "engraving" : el.key] =
      el.key === "stone" ? getStoneValue(el?.value) : el.value;
  });
  return result;
};

export const studDetailsComposer = (metaFields) => {
  const result = {};
  const key = [
    "setting",
    "stone",
    "custom_diamond",
    "center_stone_carat_weight",
    "engrave",
    "posts",
    "measurement",
    "closure",
    "chain_width",
    "chain_length",
    "seo_name",
    "seo_description",
  ];
  const studDetails = metaFields?.filter((el) => key.includes(el.key));
  forEach(studDetails, (el) => {
    result[el.key === "engrave" ? "engraving" : el.key] =
      el.key === "stone" ? getStoneValue(el?.value) : el.value;
  });
  return result;
};

export const braceletDetailsComposer = (metaFields) => {
  const result = {};
  const key = [
    "setting",
    "stone",
    "closure",
    "stone_clarity",
    "stone_color",
    "stone_size",
    "seo_name",
    "seo_description",
  ];
  const braceletDetails = metaFields?.filter((el) => key.includes(el.key));
  forEach(braceletDetails, (el) => {
    result[el.key] = el.key === "stone" ? getStoneValue(el?.value) : el.value;
  });
  return result;
};

export const necklaceDetailsComposer = (metaFields) => {
  const result = {};
  const key = [
    "stone",
    "custom_diamond",
    "center_stone_carat_weight",
    "chain_type",
    "chain_length",
    "engrave",
    "chain_width",
    "closure",
    "seo_name",
    "seo_description",
  ];
  const ringDetails = metaFields?.filter((el) => key.includes(el.key));
  forEach(ringDetails, (el) => {
    result[el.key === "engrave" ? "engraving" : el.key] =
      el.key === "stone" ? getStoneValue(el?.value) : el.value;
  });
  return result;
};

export const giftCardDetailsComposer = (metaFields) => {
  const result = {};
  const key = ["seo_name"];
  const giftCardDetails = metaFields?.filter((el) => key.includes(el.key));
  forEach(giftCardDetails, (el) => {
    result[el.key] = el.value;
  });
  return result;
};
