import { gql } from "@apollo/client";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import snakeCase from "lodash/snakeCase";
import { GRAPHQL_STOREFRONT_API_VERSION } from "./API";
import fetch from "node-fetch";
import { abortableFetch } from "abortcontroller-polyfill/dist/cjs-ponyfill";
// Initialize Storefront Client Resource
global.fetch = abortableFetch(fetch).fetch;
const client = new ApolloClient({
  // uri: `https://${
  //   process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
  //     ? process.env.SHOPIFY_STORE
  //     : "frankdarling-staging.myshopify.com"
  // }/api/2022-04/graphql.json`,
  uri: `https://${process.env.SHOPIFY_STORE}/api/${GRAPHQL_STOREFRONT_API_VERSION}/graphql.json`,
  cache: new InMemoryCache(),
  ssrMode: true,
  // headers: {
  //   "X-Shopify-Storefront-Access-Token":
  //     process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
  //       ? "221a33febe294c67788a8eae346ca6fb"
  //       : "1d1d3979804a6808b10ab4fb5db787e9",
  // },
  headers: {
    "X-Shopify-Storefront-Access-Token": "221a33febe294c67788a8eae346ca6fb",
  },
});

// Handler function for the Queries & Mutations
const API = {
  query: async (query, variables) => {
    const promiseResponse = { error: null, data: null };
    const onSuccess = (response) => {
      promiseResponse.data = response.data;
      return Promise.resolve(promiseResponse);
    };
    const onError = (error) => {
      console.log("Request Failed:", error);
      /* eslint-disable-next-line */
      const { response } = error;
      const { message } = error;
      promiseResponse.error = { message };
      return Promise.resolve(promiseResponse);
    };
    try {
      const response = await client.query({
        query,
        variables,
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  },
};

export const PRODUCT_METAFIELD_KEYS = [
  "YG-images",
  "WG-images",
  "RG-images",
  "profile",
  "stackable",
  "setting_height",
  "band_width",
  "band_height",
  "resizing_tolerance",
  "pave_carat_weight",
  "default_metal",
  "YG_video",
  "RG_video",
  "WG_video",
  "YG_hand_video",
  "RG_hand_video",
  "WG_hand_video",
  "YG-hover-image",
  "WG-hover-image",
  "RG-hover-image",
  "persona",
  "setting",
  "stone",
  "custom_diamond",
  "center_stone_carat_weight",
  "wedding_ring",
  "engrave",
  "collection_name",
  "seo_name",
  "seo_description",
  "stone_size",
  "total_carat_weight",
  "pave_size",
  "side_stone_size",
  "side_stone_tcw",
  "side_stone_pave_quality_natural",
  "side_stone_pave_quality_lab",
  // Necklace Keys
  "chain_type",
  "closure_type",
  "chain_length",
  "chain_width",
  // Studs Keys
  "stone_clarity",
  "stone_color",
  "closure",
  "posts",
];

const metafieldsQuery = () => {
  let query = "";

  for (const key of PRODUCT_METAFIELD_KEYS) {
    query += `
    ${snakeCase(key)}: metafield(namespace: "products", key: "${key}") {
      id
      namespace
      key
      value
      type
      description
      createdAt
      updatedAt
    }
    `;
  }
  return query;
};

// Queries

export const GET_PRODUCT_BY_HANDLE = gql`
  query product($handle: String) {
    product(handle: $handle) {
      descriptionHtml
      createdAt
      featuredImage {
        altText
        height
        id
        width
        url
      }
      tags
      handle
      id
      ${metafieldsQuery()}
      images(first: 250) {
        nodes {
          altText
          height
          id
          width
          url
        }
      }
      options {
        id
        name
        values
      }
      productType
      publishedAt
      tags
      title
      updatedAt
      vendor
      variants(first: 250) {
        nodes {
          availableForSale
          barcode
          compareAtPriceV2 {
            amount
          }
          currentlyNotInStock
          id
          image {
            altText
            height
            id
            width
            url
          }
          priceV2 {
            amount
          }
          quantityAvailable
          requiresShipping
          selectedOptions {
            name
            value
          }
          sku
          title
          weight
          weightUnit
        }
      }
    }
  }
`;

export const GET_PRODUCTS = (gids) => {
  const query = gql`
    query GetProducts {
      nodes(ids: ${gids}) {
        ... on Product {
          descriptionHtml
          createdAt
          featuredImage {
            altText
            height
            id
            width
            url
          }
          tags
          handle
          id
          ${metafieldsQuery()}
          images(first: 250) {
            nodes {
              altText
              height
              id
              width
              url
            }
          }
          options {
            id
            name
            values
          }
          productType
          publishedAt
          tags
          title
          updatedAt
          vendor
          variants(first: 250) {
            nodes {
              availableForSale
              barcode
              compareAtPriceV2 {
                amount
              }
              currentlyNotInStock
              id
              image {
                altText
                height
                id
                width
                url
              }
              priceV2 {
                amount
              }
              quantityAvailable
              requiresShipping
              selectedOptions {
                name
                value
              }
              sku
              title
              weight
              weightUnit
            }
          }
        }
      }
    }
  `;
  return query;
};

export const GET_ALL_PRODUCTS_BY_TYPE = (type) => {
  const product_type = type === "all" ? `""` : `"product_type:${type}"`;
  const query = gql`
    query GetProducts {
      products(first: 250, query: ${product_type}) {
        edges {
          node  {
            id
            title
            handle
            productType
            description
            ${metafieldsQuery()}
          }
        }
      }
    }
  `;
  return query;
};

export default API;
