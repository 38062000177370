import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { GRAPHQL_STOREFRONT_API_VERSION } from "../services/API";

const httpLink = createHttpLink({
  uri: `https://frank-darling.myshopify.com/api/${GRAPHQL_STOREFRONT_API_VERSION}/graphql.json`,
});
const middlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": `${process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN}`,
  },
}));

const client = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
});

/* eslint-disable react/prop-types */
/**
 *  Apollo provider
 * @param {node} param0
 * @return {object}
 */
function Apollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
