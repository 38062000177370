/* eslint-disable require-jsdoc */
import { useState, createContext, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import remove from "lodash/remove";
import findIndex from "lodash/findIndex";
import API from "./FrontEndAPI";
import { AppContext } from "../context/AppContext";
import { delay, isROWUser } from "../_helpers/functions";

const defaultCart = {
  products: [],
};

export const CartContext = createContext();
/* eslint-disable react/prop-types */
/**
 * All cart states
 * @return {node}
 */
export default function CartProvider({ children }) {
  const [cart, updateCart] = useState(defaultCart);
  const [showCart, setShowCart] = useState({ value: false, showId: "" });
  const [preference, setPreference] = useState({ id: "", type: "" });
  const { allowCookies } = useContext(AppContext);
  const ROWUser = isROWUser();

  useEffect(() => {
    const stateFromStorage = window.localStorage.getItem("frankdarling-cart");
    const data = stateFromStorage && JSON.parse(stateFromStorage);
    if (!isEmpty(data) && isArray(data?.products)) {
      // creating a new array of products to remove empty variants from cart
      const updatedData = data.products.filter((product, idx) => {
        // condition to solve the comparison crash - check for rings with following conditions
        if (
          (product["type"] === "ring" || product["type"] === "necklace") &&
          product["preference"] === "primary" &&
          product.hasOwnProperty("diamond_id") &&
          product.hasOwnProperty("diamond_detail")
        ) {
          const diamond = data.products.filter((p, idx) => {
            if (p.type === "diamond" && p.id == product["diamond_id"])
              return true;

            return false;
          });

          // check if the product is emoty, remove it so that it doesn't crash for that user
          if (isEmpty(diamond)) return false;
        }

        // remove empty variants from cart causing site to crash
        if (
          (product["type"] === "ring" ||
            product["type"] === "necklace" ||
            product["type"] === "stud" ||
            product["type"] === "bracelet") &&
          !product.hasOwnProperty("variant")
        ) {
          return false;
        }
        return true;
      });

      updateCart({
        products: updatedData,
      });
    } else {
      updateCart(defaultCart);
    }
  }, []);

  useEffect(() => {
    const data = JSON.stringify(cart);
    window.localStorage.setItem("frankdarling-cart", data);
  }, [cart]);

  useEffect(() => {
    if (showCart.value) {
      // eslint-disable-next-line no-inner-declarations
      async function fixDiamondPrice() {
        const costChangedArray = [];
        const soldDiamondArray = [];
        const cartDiamonds = filter(cart.products, { type: "diamond" });
        for (let i = 0; i < cartDiamonds.length; i++) {
          const diamondID = cartDiamonds[i].id;
          const diamondCost = Number(cartDiamonds[i].diamond_detail["Cost"]);
          const { data: diamondData, error } = await API.get(
            `https://api.frankdarling.${
              process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
                ? "com"
                : "xyz"
            }/diamond/${diamondID}?diamondDetail=status-detail`
          );

          // check if the user is ROW user and increase price
          if (ROWUser) {
            diamondData.cost = diamondData.cost + 150;
          }

          delay(0);
          if (error) {
            console.log(error);
          }
          if (
            (diamondData?.status === "SOLD_BY_FD" ||
              diamondData?.status === "SOLD_BY_SUPPLIER") &&
            diamondData?.id === diamondID
          ) {
            soldDiamondArray.push({
              id: diamondID,
            });
          } else if (
            diamondData?.cost !== diamondCost &&
            diamondData?.id === diamondID
          ) {
            costChangedArray.push({
              id: diamondID,
              cost: diamondData?.cost,
            });
          }
        }
        updateCart((prev) => {
          const cart = { ...prev };
          for (let i = 0; i < soldDiamondArray.length; i++) {
            for (let j = 0; j < cart.products.length; j++) {
              if (
                String(cart.products[j].id) === String(soldDiamondArray[i].id)
              ) {
                cart.products[j].diamond_detail["Status"] = "SOLD";
                break;
              }
            }
          }
          return cart;
        });
        updateCart((prev) => {
          const cart = { ...prev };
          for (let i = 0; i < costChangedArray.length; i++) {
            for (let j = 0; j < cart.products.length; j++) {
              if (
                String(cart.products[j].id) === String(costChangedArray[i].id)
              ) {
                cart.products[j].diamond_detail["Cost"] =
                  costChangedArray[i].cost;
                break;
              }
            }
          }
          return cart;
        });
      }
      fixDiamondPrice();
      document.body.classList.add("body--modal-open");
    } else {
      document.body.classList.remove("body--modal-open");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCart]);

  /**
   * Function to add product in cart
   * @param {object} product
   * @param {string} productType
   * @param {boolean} addWithoutDiamond
   * @param {object} pairDiamond
   */
  function addToCart(product, productType, addWithoutDiamond, pairDiamond) {
    if (product) {
      const _learnq = window._learnq || [];
      _learnq.push(["track", "Added to Cart", product]);
    }
    if (
      allowCookies &&
      process.env.NEXT_PUBLIC_NOW_URL === "https://frankdarling.com"
    ) {
      if (productType === "ring") {
        try {
          const fbEvent = {
            content_name: product.ring_detail.title,
            content_category: "Apparel & Accessories > Jewelry > Rings",
            content_ids: [product.variant.id],
            content_type: "product",
            value: product.variant.price,
            currency: "USD",
          };
          // eslint-disable-next-line no-undef
          fbq("track", "AddToCart", fbEvent);
        } catch (error) {
          console.log("failed to trigger event");
        }
      } else if (productType === "necklace") {
        try {
          const fbEvent = {
            content_name: product.necklace_detail.title,
            content_category: "Apparel & Accessories > Jewelry > Necklaces",
            content_ids: [product.variant.id],
            content_type: "product",
            value: product.variant.price,
            currency: "USD",
          };
          // eslint-disable-next-line no-undef
          fbq("track", "AddToCart", fbEvent);
        } catch (error) {
          console.log("failed to trigger event");
        }
      } else if (productType === "stud") {
        try {
          const fbEvent = {
            content_name: product.stud_detail.title,
            content_category: "Apparel & Accessories > Jewelry > Earrings",
            content_ids: [product.variant.id],
            content_type: "product",
            value: product.variant.price,
            currency: "USD",
          };
          // eslint-disable-next-line no-undef
          fbq("track", "AddToCart", fbEvent);
        } catch (error) {
          console.log("failed to trigger event");
        }
      }
    }
    const primaryProductExistInCart = find(cart?.products, {
      preference: "primary",
      id:
        productType === "ring" ||
        productType === "necklace" ||
        productType === "stud"
          ? preference.id
          : Number(preference.id),
    });
    const diamondShapeCheck = includes(
      primaryProductExistInCart?.[
        preference?.type === "necklace"
          ? "necklace_detail"
          : preference?.type === "stud"
          ? "stud_detail"
          : "ring_detail"
      ]?.stone,
      product?.diamond_detail?.Shape
    );
    const diamondShapeMatch =
      productType === "diamond" ? diamondShapeCheck : true;
    // studs will be without diamonds
    if (
      preference.id &&
      !addWithoutDiamond &&
      primaryProductExistInCart &&
      diamondShapeMatch
    ) {
      if (productType === "ring") {
        const diamondToUpdateIndx = findIndex(
          cart.products,
          (el) => el.id === preference.id && !el.ring_id
        );
        const diamondToUpdate = cart.products.splice(diamondToUpdateIndx, 1)[0];
        diamondToUpdate["ring_id"] = product.id;
        updateCart({
          products: [
            ...cart.products,
            diamondToUpdate,
            { ...product, diamond_id: preference.id },
          ],
        });
      } else if (productType === "necklace") {
        const diamondToUpdateIndx = findIndex(
          cart.products,
          (el) => el.id === preference.id && !el.necklace_id
        );
        const diamondToUpdate = cart.products.splice(diamondToUpdateIndx, 1)[0];
        diamondToUpdate["necklace_id"] = product.id;
        updateCart({
          products: [
            ...cart.products,
            diamondToUpdate,
            { ...product, diamond_id: preference.id },
          ],
        });
      } else if (productType === "diamond") {
        let idKey = "";
        const ringToUpdateIndx = findIndex(cart.products, (el) => {
          if (el?.necklace_detail) {
            const condition =
              Number(el.id) === Number(preference.id) && !el.diamond_id;
            if (condition) idKey = "necklace_id";
            return condition;
          } else {
            const condition =
              Number(el.id) === Number(preference.id) &&
              (isNaN(el.ring_detail.ring_size)
                ? el.ring_detail.ring_size
                : Number(el.ring_detail.ring_size)) ===
                (isNaN(preference.size)
                  ? preference.size
                  : Number(preference.size)) &&
              el.ring_detail.engrave === preference.engrave &&
              !el.diamond_id;
            if (condition) idKey = "ring_id";
            return condition;
          }
        });
        const ringToUpdate = cart.products.splice(ringToUpdateIndx, 1)[0];
        ringToUpdate["diamond_id"] = product.id;
        updateCart({
          products: [
            ...cart.products,
            ringToUpdate,
            { ...product, [idKey ?? "ring_id"]: preference.id },
          ],
        });
      }
    } else {
      updateCart((prev) => {
        const cart = { ...prev };
        cart.products.push(product);
        if (pairDiamond) {
          cart.products.push(pairDiamond);
        }
        return cart;
      });
    }
  }

  /**
   * Function to check if product is added in cart
   * @param {node} id
   * @param {string} type
   * @param {number} size
   * @param {string} engrave
   * @return {boolean}
   */
  function isAddedToCart({ id, type, size, engrave, variantID }) {
    if (type === "ring") {
      return (
        filter(cart.products, (el) => {
          // eslint-disable-next-line no-prototype-builtins
          const key = el?.hasOwnProperty("necklace_detail")
            ? "necklace_detail"
            : "ring_detail";
          const condition = el?.hasOwnProperty("necklace_detail")
            ? el?.id === Number(id) && variantID
              ? el?.variant?.id === variantID
              : true
            : el?.id === Number(id) &&
              (isNaN(el[key].ring_size)
                ? el[key].ring_size
                : Number(el[key].ring_size)) ===
                (isNaN(size) ? size : Number(size)) &&
              el[key].engrave === engrave &&
              variantID
            ? el?.variant?.id === variantID
            : true;

          return condition;
        }).length > 0
      );
    } else if (type === "necklace" || type === "stud") {
      return (
        filter(cart.products, (el) =>
          el?.id === Number(id) && variantID
            ? el?.variant?.id === variantID
            : true
        ).length > 0
      );
    } else return filter(cart.products, { id: id }).length > 0;
  }

  /**
   * Function to remove item from cart
   * @param {object} param0
   */
  function removeFromCart({ ringId, diamondId, id, studId, necklaceId }) {
    updateCart((prev) => {
      const cart = { ...prev };
      if ((necklaceId || ringId) && diamondId && !id) {
        // removing stone
        remove(
          cart.products,
          (el) =>
            el.id === diamondId &&
            (Number(el.ring_id) === Number(ringId) ||
              Number(el.necklace_id) === Number(necklaceId))
        );
        // removing setting
        remove(
          cart.products,
          (el) =>
            (el.id === ringId || el.id === necklaceId) &&
            el.diamond_id === diamondId
        );
      } else if (studId) {
        const removeIndex = findIndex(cart.products, (el) => el.id === studId);
        cart.products.splice(removeIndex, 1);
      } else {
        const removeIndex = findIndex(
          cart.products,
          (el) =>
            el.id === id && !el.diamond_id && !el.ring_id && !el.necklace_id
        );
        cart.products.splice(removeIndex, 1);
      }
      return cart;
    });
  }

  /**
   * Function to change setting/stone from current cart
   * @param {object} param0 Current item
   */
  function changeItem({ currentItem, changeItem, type, mode }) {
    updateCart((prev) => {
      const cart = { ...prev };
      if (type === "ring") {
        remove(cart.products, (el) => {
          return (
            Number(el.id) === Number(currentItem.id) &&
            Number(el.variant.id) === Number(currentItem.variant_id) &&
            (isNaN(el.ring_detail.ring_size)
              ? el.ring_detail.ring_size
              : Number(el.ring_detail.ring_size)) ===
              (isNaN(currentItem.ring_size)
                ? currentItem.ring_size
                : Number(currentItem.ring_size)) &&
            el.ring_detail.engrave === currentItem.engrave &&
            (mode === "change" || preference.id
              ? el.diamond_id === preference.id
              : true)
          );
        });
        if (mode === "change" || preference.id) {
          const diamondToUpdateIndx = findIndex(
            cart.products,
            (el) =>
              el.id === preference.id &&
              Number(el.ring_id) === Number(currentItem.id)
          );
          const diamondToUpdate = cart.products.splice(
            diamondToUpdateIndx,
            1
          )[0];
          diamondToUpdate["ring_id"] = changeItem.id;
          cart.products.push(diamondToUpdate);
          cart.products.push({
            ...changeItem,
            diamond_id: preference.id,
            ...((mode === "change" || preference.id) && {
              diamond_detail: diamondToUpdate.diamond_detail,
            }),
          });
        } else {
          cart.products.push({
            ...changeItem,
            diamond_id: preference.id,
          });
        }
      } else if (type === "diamond") {
        remove(cart.products, (el) => {
          return (
            el.id === currentItem.id &&
            Number(
              preference.type === "necklace" ? el.necklace_id : el.ring_id
            ) === Number(preference.id)
          );
        });
        const ringCheck = (el) =>
          Number(el.id) === Number(preference.id) &&
          (isNaN(el.ring_detail.ring_size)
            ? el.ring_detail.ring_size
            : Number(el.ring_detail.ring_size)) ===
            (isNaN(preference.size)
              ? preference.size
              : Number(preference.size)) &&
          el.ring_detail.engrave === preference.engrave &&
          el.diamond_id === currentItem.id;

        const necklaceCheck = (el) =>
          Number(el.id) === Number(preference.id) &&
          el.diamond_id === currentItem.id;

        const ringToUpdateIndx = findIndex(cart.products, (el) =>
          preference.type === "necklace" ? necklaceCheck(el) : ringCheck(el)
        );
        const ringToUpdate = cart.products.splice(ringToUpdateIndx, 1)[0];
        ringToUpdate["diamond_id"] = changeItem.id;
        ringToUpdate["diamond_detail"] = changeItem.diamond_detail;
        cart.products.push({
          ...changeItem,
          [preference.type === "necklace" ? "necklace_id" : "ring_id"]: Number(
            preference.id
          ),
        });
        cart.products.push(ringToUpdate);
      } else if (
        type === "stud" ||
        type === "bracelet" ||
        type === "misc" ||
        type === "necklace"
      ) {
        remove(cart.products, (el) => {
          return (
            Number(el.id) === Number(currentItem.id) &&
            Number(el.variant.id) === Number(currentItem.variant_id)
          );
        });
        cart.products.push({
          ...changeItem,
          diamond_id: preference.id,
        });
      }
      return cart;
    });
  }

  /**
   * function to get ring title by variant ID
   * @param {number} id Ring ID
   * @return {string}
   */
  function getRingTitle(id) {
    const ringObj = filter(cart.products, { id: Number(id) });
    return ringObj[0]?.ring_detail?.title;
  }

  /**
   * function to get ring/diamond shape by ID
   * @param {number} id Ring/Diamond ID
   * @param {string} type type
   * @return {string}
   */
  function getShape(id, type) {
    const productObj = filter(
      cart.products,
      (el) => String(el.id) === String(id)
    );
    if (type === "ring") {
      return productObj[0]?.ring_detail?.stone;
    } else if (type === "necklace") {
      return productObj[0]?.necklace_detail?.stone;
    } else if (type === "diamond") {
      return productObj[0]?.diamond_detail["Shape"];
    }
  }

  /**
   * Function to check if ring is available to add diamond in it
   * @param {node} id
   * @param {number} size
   * @param {string} engrave
   * @param {string} type
   * @return {boolean}
   */
  function isAvailableForSecondary({ id, size, engrave, type }) {
    if (type === "ring") {
      return !!filter(
        cart.products,
        (el) =>
          el.id === Number(id) &&
          el.ring_detail.ring_size === (isNaN(size) ? size : Number(size)) &&
          el.ring_detail.engrave === engrave &&
          el.preference === "primary" &&
          !el.diamond_id
      ).length;
    } else if (type === "necklace") {
      return !!filter(
        cart.products,
        (el) =>
          el.id === Number(id) && el.preference === "primary" && !el.diamond_id
      ).length;
    } else if (type === "diamond") {
      return !!filter(
        cart.products,
        (el) => el.id === id && el.preference === "primary" && !el.ring_id
      ).length;
    }
  }

  function addDefaultDiamond({ setting, product }) {
    const primaryProductExistInCart = find(cart?.products, {
      preference: "primary",
      id: setting.id,
    });
    const diamondShapeCheck = includes(
      primaryProductExistInCart?.[
        setting.type === "ring" ? "ring_detail" : "necklace_detail"
      ]?.stone,
      product?.diamond_detail?.Shape
    );

    if (primaryProductExistInCart && diamondShapeCheck) {
      const settingToUpdateIndx = findIndex(cart.products, (el) => {
        const condition =
          setting.type === "necklace"
            ? Number(el.id) === Number(setting.id) && !el.diamond_id
            : Number(el.id) === Number(setting.id) &&
              (isNaN(el.ring_detail.ring_size)
                ? el.ring_detail.ring_size
                : Number(el.ring_detail.ring_size)) ===
                (isNaN(setting.size) ? setting.size : Number(setting.size)) &&
              el.ring_detail.engrave === setting.engrave &&
              !el.diamond_id;
        return condition;
      });
      const settingToUpdate = cart.products.splice(settingToUpdateIndx, 1)[0];
      settingToUpdate["diamond_id"] = product.id;
      updateCart({
        products: [
          ...cart.products,
          settingToUpdate,
          {
            ...product,
            [setting.type === "ring" ? "ring_id" : "necklace_id"]: setting.id,
          },
        ],
      });
    }
  }

  const value = {
    cart,
    updateCart,
    addToCart,
    removeFromCart,
    isAddedToCart,
    showCart,
    setShowCart,
    preference,
    setPreference,
    changeItem,
    getRingTitle,
    isAvailableForSecondary,
    getShape,
    addDefaultDiamond,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

/**
 * return cart object
 * @return {object}
 */
export function useCart() {
  const cart = useContext(CartContext);
  if (cart === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return cart;
}
