import isEmpty from "lodash/isEmpty";
import mapKeys from "lodash/mapKeys";
import findIndex from "lodash/findIndex";
import startCase from "lodash/startCase";
import filter from "lodash/filter";
import { useState, createContext, useContext, useEffect } from "react";
import useSnackbar from "../hooks/useSnackbar";
import { MissingDiamond } from "../_helpers/Icons/miscellaneousIcons";
import Link from "next/link";
import queryString from "query-string";
import { useRouter } from "next/router";
import useDialog from "../hooks/useDialog";
import { getRakutenParams } from "../_helpers/functions";

export const CompareContext = createContext();
/* eslint-disable react/prop-types */
/**
 * All Fave state
 * @return {node}
 */
export default function CompareProvider({ children }) {
  const [compareDiamond, updateCompareDiamond] = useState([]);
  const [totalCompareItems, setTotalCompareItems] = useState(0);
  const [filteredCompareDiamond, setFilteredCompareDiamond] = useState([]);
  const { snackbar } = useSnackbar();
  const { dialogType } = useDialog();
  const router = useRouter();

  const getInitialCompareItems = () => {
    const stateFromStorage = window.localStorage.getItem(
      "frankdarling-compare"
    );
    const data = stateFromStorage && JSON.parse(stateFromStorage);
    if (!isEmpty(data)) {
      updateCompareDiamond(data);
    } else {
      updateCompareDiamond([]);
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("storage", function (e) {
      if (e.key === "frankdarling-compare") {
        getInitialCompareItems();
      }
    });
  }

  useEffect(() => {
    getInitialCompareItems();
  }, []);

  useEffect(() => {
    const data = JSON.stringify(compareDiamond);
    window.localStorage.setItem("frankdarling-compare", data);
    const totals = Number(compareDiamond.length);
    setTotalCompareItems(totals);
  }, [compareDiamond]);

  /**
   * Function to add product to wishlist
   * @param {object} datas object of product and type
   * @param {boolean} updateFilterData to check whether we have to update filtered data
   */
  function addToCompare(datas, updateFilterData = false) {
    const mappedDiamondData = mapKeys(datas, (value, key) => startCase(key));
    const data = {
      ...mappedDiamondData,
      ...{
        "Depth%": datas["depth_per"],
        "Table%": datas["table_per"],
        "Video URL": datas["video_url"],
        "Vision URL": datas["vision_url"],
        Certificate: datas["certificate_url"],
        mp4: datas["mp4"],
        "GIA Number": datas["gia_number"],
        "Supplier ID": datas["supplier_id"],
        "Diamond ID": datas["diamond_id"],
        "Inventory Price": datas["champagne"] ? datas["cost"] * 2 : null,
      },
    };

    const isAdded = findIndex(compareDiamond, {
      Id: data?.Id,
    });
    if (isAdded === -1) {
      if (totalCompareItems < 5) {
        updateCompareDiamond([...compareDiamond, data]);
        showSnackbar();
      } else {
        compareLimitSnackbar();
      }
      if (updateFilterData) {
        setFilteredCompareDiamond([...filteredCompareDiamond, data]);
      }
    } else {
      const newData = filter(compareDiamond, function (obj) {
        return obj?.Id !== data?.Id;
      });
      updateCompareDiamond(newData);
      if (updateFilterData) {
        const newFilterData = filter(filteredCompareDiamond, function (obj) {
          return obj?.Id !== data?.Id;
        });
        setFilteredCompareDiamond(newFilterData);
        return;
      }
    }
  }

  /**
   * Function to show snackbar
   */
  const showSnackbar = () => {
    snackbar({
      message: "ADDED TO COMPARISON",
      variant: "success",
      icon: (
        <MissingDiamond
          className="w-9 h-9 border-white -mt-1.5 "
          snackbarIcon={true}
        />
      ),
      customElementRow: (
        <div className="flex justify-center mt-2.5 -ml-3">
          <Link
            legacyBehavior
            href={`/comparison?${queryString.stringify({
              ...(dialogType.bottom && router.query && router.query.ranMID
                ? getRakutenParams
                : {}),
            })}`}
          >
            <a className="ml-12 underline capitalize">
              View Your Diamond Comparison
            </a>
          </Link>
        </div>
      ),
    });
  };

  /**
   * Function to show snackbar when there are 5 diamonds in comparision
   */
  const compareLimitSnackbar = () => {
    snackbar({
      message: "Only add upto 5 Diamonds",
      variant: "success",
      icon: (
        <MissingDiamond
          className="w-9 h-9 border-white -mt-1.5 "
          snackbarIcon={true}
        />
      ),
      customElementRow: (
        <div className="flex justify-center mt-2.5 -ml-3">
          <Link
            legacyBehavior
            href={`/comparison?${queryString.stringify({
              ...(dialogType.bottom && router.query && router.query.ranMID
                ? getRakutenParams
                : {}),
            })}`}
          >
            <a className="ml-12 underline capitalize">
              View Your Diamond Comparison
            </a>
          </Link>
        </div>
      ),
    });
  };

  /**
   * Function to check if item is already in wishlist
   * @param {string} diamondId
   * @return {boolean}
   */
  const addedToCompare = (diamondId) => {
    if (
      findIndex(compareDiamond, {
        Id: diamondId,
      }) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const value = {
    compareDiamond,
    addToCompare,
    addedToCompare,
    totalCompareItems,
    setTotalCompareItems,
    filteredCompareDiamond,
    setFilteredCompareDiamond,
  };

  return (
    <CompareContext.Provider value={value}>{children}</CompareContext.Provider>
  );
}

/**
 * return wishlist object
 * @return {object}
 */
export function useCompare() {
  const compare = useContext(CompareContext);
  if (compare === undefined) {
    throw new Error("useCompare must be used within a CompareProvider");
  }
  return compare;
}
