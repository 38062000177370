import isEmpty from "lodash/isEmpty";
import { useState, createContext, useContext, useEffect } from "react";

const defaultCheckout = {
  id: "",
  webUrl: "",
  ready: false,
  orderStatusUrl: "",
};

export const CheckoutContext = createContext();
/* eslint-disable react/prop-types */
/**
 * All Checkout state
 * @return {node}
 */
export default function CheckoutProvider({ children }) {
  const [checkout, updateCheckout] = useState(defaultCheckout);

  useEffect(() => {
    const stateFromStorage = window.localStorage.getItem(
      "frankdarling-checkout"
    );
    const data = stateFromStorage && JSON.parse(stateFromStorage);

    if (!isEmpty(data)) {
      updateCheckout(data);
    } else {
      updateCheckout(defaultCheckout);
    }
  }, []);

  useEffect(() => {
    const data = JSON.stringify(checkout);
    window.localStorage.setItem("frankdarling-checkout", data);
  }, [checkout]);

  const value = {
    checkout,
    updateCheckout,
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}

/**
 * return checkout object
 * @return {object}
 */
export function useCheckout() {
  const checkout = useContext(CheckoutContext);
  if (checkout === undefined) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return checkout;
}
