import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSnackbar from "../../hooks/useSnackbar";
import { WishlistHeart } from "../../_helpers/Icons/headerIcons";

const Toast = ({
  message,
  variant,
  autoDelete,
  onExit,
  dismissTime,
  icon,
  customElementRow,
}) => {
  const [displayMsg, setDisplayMsg] = useState(message);
  const { setToast } = useSnackbar();

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && message && displayMsg) {
        setDisplayMsg("");
        setToast({});
        onExit();
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMsg, autoDelete, dismissTime, message]);

  const setBgColor = (variant) => {
    switch (variant) {
      case "success": {
        return "bg-theme-blue";
      }
      case "error": {
        return "bg-red-600";
      }
      case "yellow": {
        return "bg-yellow-600";
      }
    }
  };

  return (
    <>
      {displayMsg && (
        <div
          className={`${setBgColor(
            variant
          )} flex flex-col items-center animate-slideLeft fixed right-0 top-0 text-white mt-12 mr-3 px-12 py-6 rounded-lg z-50`}
          style={{
            minWidth: 200,
            maxWidth: 400,
          }}
          role="alert"
        >
          <div className="flex space-x-4 text-white">
            <div className="w-8 h-6">{icon}</div>
            <div className="flex flex-1 text-xl leading-tight font-medium font-sans">
              {displayMsg}
            </div>
          </div>
          {customElementRow}
        </div>
      )}
    </>
  );
};

Toast.defaultProps = {
  message: "",
  variant: "success",
  autoDelete: true,
  dismissTime: 3000,
  onExit: () => {},
  icon: <WishlistHeart className="w-8 h-6 text-white" />,
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  autoDelete: PropTypes.bool,
  variant: PropTypes.string,
  dismissTime: PropTypes.number,
  onExit: PropTypes.func,
  icon: PropTypes.node,
  customElementRow: PropTypes.node,
};

export default Toast;
