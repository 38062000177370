import { useState, createContext, useContext, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import takeRight from "lodash/takeRight";
import filter from "lodash/filter";

const defaultCart = {
  rings: [],
  studs: [],
  necklaces: [],
  diamonds: [],
};

export const RecentViewContext = createContext();
/* eslint-disable react/prop-types */
/**
 * All recentViewed states
 * @return {node}
 */
export default function RecentViewProvider({ children }) {
  const [recentViewed, updateRecentViewed] = useState(defaultCart);

  useEffect(() => {
    const stateFromStorage = window.localStorage.getItem("frankdarling-recent");
    const data = stateFromStorage && JSON.parse(stateFromStorage);
    if (!isEmpty(data) && isArray(data?.diamonds)) {
      updateRecentViewed(data);
    } else {
      updateRecentViewed(defaultCart);
    }
  }, []);

  useEffect(() => {
    const data = JSON.stringify(recentViewed);

    window.localStorage.setItem("frankdarling-recent", data);
  }, [recentViewed]);

  /**
   * Function to add product in recentViewed
   * @param {string} productId
   * @param {object} type
   */
  function addToRecent(productId, type) {
    if (type === "diamond") {
      updateRecentViewed((prev) => {
        const previousFour = takeRight(prev.diamonds, 4);

        if (
          !filter(previousFour, (el) => {
            return el === productId;
          }).length
        ) {
          return {
            ...prev,
            diamonds: [...previousFour, productId],
          };
        } else {
          return {
            ...prev,
            diamonds: [...prev.diamonds],
          };
        }
      });
    } else {
      updateRecentViewed((prev) => {
        const previousFour = takeRight(prev[`${type}s`], 4);
        if (
          !filter(previousFour, (el) => {
            return el === Number(productId);
          }).length
        ) {
          return {
            ...prev,
            [`${type}s`]: [...previousFour, productId],
          };
        } else {
          return {
            ...prev,
            [`${type}s`]: [...prev[`${type}s`]],
          };
        }
      });
    }
  }

  const value = {
    recentViewed,
    addToRecent,
  };

  return (
    <RecentViewContext.Provider value={value}>
      {children}
    </RecentViewContext.Provider>
  );
}

/**
 * return recentViewed object
 * @return {object}
 */
export function useRecentView() {
  const recentViewed = useContext(RecentViewContext);
  if (recentViewed === undefined) {
    throw new Error("useRecentView must be used within a RecentViewProvider");
  }
  return recentViewed;
}
