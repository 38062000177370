import React from "react";
import App from "next/app";
// import { PageTransition } from "next-page-transitions";
// import Loader from "../components/Loader";
import "../styles/main.scss";
import CartProvider from "../src/services/cart";
import FaveProvider from "../src/services/fave";
import CompareProvider from "../src/services/compare";
import CheckoutProvider from "../src/services/checkout";
import RecentViewProvider from "../src/services/recentView";
import Apollo from "../src/providers/apolloProvider";
import DialogProvider from "../src/providers/dialogProviders";
import AppProvider from "../src/context/AppContext";
import DocumentProvider from "../src/context/DocumentContext";
import SnackbarProvider from "../src/providers/snackbarProvider";
import { Logger, delay, getCookie } from "../src/_helpers/functions";
import FirebaseProvider from "../src/services/firebase";
import LocalePriceProvider from "../src/providers/localePriceProvider";
import isEmpty from "lodash/isEmpty";
import { getCurrency } from "../src/_helpers/currencyDictionaryMapper";
import { Analytics } from "@vercel/analytics/react";

// const TIMEOUT = 100;

/* eslint-disable require-jsdoc */
class MyApp extends App {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
  }
  state = {
    currency: {
      currency: "USD",
      currencySymbol: "$",
      exchangeRate: 1,
    },
  };
  componentDidMount() {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return;
    }

    function openCCPABanner(anchorRef) {
      const cookie = getCookie("OneTrustWPCCPAGoogleOptOut");
      const ifOptOutExists = cookie !== null;
      if (!ifOptOutExists) {
        anchorRef.current.click();
        window.__rmuspc = "YN";
      } else {
        window.__rmuspc = `Y${cookie === "true" ? "Y" : "N"}`;
      }

      // Code for setting window object whenever the user either opts in or opts out
      const button = document.querySelector(".ot-ccpa-optout__confirm");
      const boolean = document.querySelector(".ot-ccpa-optout__option-right");

      if (button) {
        button.addEventListener("click", () => {
          const checked = window.getComputedStyle(boolean)["opacity"] == 1;
          const text = checked ? "N" : "Y";
          window.__rmuspc = `Y${text}`;
        });
      }
    }
    // eslint-disable-next-line new-cap
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone == "America/Los_Angeles" && document) {
      const script = document.createElement("script");
      if (script) {
        script.innerHTML = `var otCcpaScript = document.createElement('script'),script1 = document.getElementsByTagName('script')[0];otCcpaScript.src = 'https://cookie-cdn.cookiepro.com/ccpa-optout-solution/v1/ccpa-optout.js';otCcpaScript.async = false;otCcpaScript.type = 'text/javascript';script1.parentNode.insertBefore(otCcpaScript, script1); window.otccpaooSettings = {layout: {dialogueLocation:"left",primaryColor: "#1C1794",secondaryColor: "#ffffff",button:{primary:"#1C1794", secondary:"#ffffff", } },dialogue: {email: {display:false,title:"",url:"",},lspa: {accepted:true,},phone: {display:false,title:"",url:"",},dsar: {display:false,title:"",url:"",},intro: { title:"Do Not Sell My Personal Information",description:"Exercise your consumer right to opt out.",}, privacyPolicy: {title:"",url:"", }, optOut: {title:"Personalized Advertisements",description:"Turning this off will opt you out of personalized advertisements on this website.",frameworks:["iab","gam"],}, location:"all",confirmation: {text: "Confirm",}, } };`;
        document.head.appendChild(script);
        delay(1000).then(() => {
          openCCPABanner(this.anchorRef);
        });
      }
    } else {
      window.__rmuspc = "--";
    }
    async function fetchExchangeValue() {
      try {
        const response = await fetch(
          `https://api.frankdarling.${
            process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
              ? "com"
              : "xyz"
          }/currency`
        ).then((res) => res.json());

        if (!isEmpty(response?.currency)) {
          return response.currency;
        }
      } catch (error) {
        Logger.print(error);
        throw Error(error);
      }
    }
    fetchExchangeValue().then((currency) => {
      const localCurrency = getCurrency();
      const exchangeRate = currency?.rates[localCurrency.currency];

      this.setState({
        currency: {
          ...localCurrency,
          exchangeRate: exchangeRate ? exchangeRate : 1,
        },
      });
    });
  }
  render() {
    const { Component, pageProps, router } = this.props;
    return (
      <>
        <div className="hidden">
          <a
            ref={this.anchorRef}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return false;
            }}
            data-ot-ccpa-opt-out="link"
            style={{ display: "none" }}
          >
            Do Not Sell My Personal Information
          </a>
        </div>
        {/* <PageTransition
          timeout={TIMEOUT}
          // classNames="page-transition"
          loadingComponent={<Loader />}
          loadingDelay={100}
          loadingTimeout={{
            enter: TIMEOUT,
            exit: 0,
          }}
          loadingClassNames="loading-indicator"
        > */}
        <>
          <Apollo key={router.route}>
            <DocumentProvider>
              <LocalePriceProvider currency={this.state.currency}>
                <AppProvider>
                  <DialogProvider>
                    <CheckoutProvider>
                      <CartProvider>
                        <SnackbarProvider>
                          <RecentViewProvider>
                            <FaveProvider>
                              <CompareProvider>
                                <FirebaseProvider>
                                  <Component {...pageProps} />
                                </FirebaseProvider>
                              </CompareProvider>
                            </FaveProvider>
                          </RecentViewProvider>
                        </SnackbarProvider>
                      </CartProvider>
                    </CheckoutProvider>
                  </DialogProvider>
                </AppProvider>
              </LocalePriceProvider>
            </DocumentProvider>
          </Apollo>
          <Analytics debug={false} />
        </>
        {/* </PageTransition> */}
        {/* <style jsx global>{`
          .page-transition-enter {
            opacity: 0;
            transform: translate3d(0, 20px, 0);
          }
          .page-transition-enter-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
          }
          .page-transition-exit {
            opacity: 1;
          }
          .page-transition-exit-active {
            opacity: 0;
            transition: opacity ${TIMEOUT}ms;
          }
          .loading-indicator-appear,
          .loading-indicator-enter {
            opacity: 0;
          }
          .loading-indicator-appear-active,
          .loading-indicator-enter-active {
            opacity: 1;
            transition: opacity ${TIMEOUT}ms;
          }
        `}</style> */}
      </>
    );
  }
}

export default MyApp;
